/**
 * Create the store with dynamic reducers
 */

import { configureStore } from "@reduxjs/toolkit";
import throttle from "lodash/throttle";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducerFun from "./reducers";
import createPersistedReducer from "./persistConfig";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";

const rootReducer = createPersistedReducer(rootReducerFun);

export const store = configureStore({
  reducer: { rootReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat()
});

store.subscribe(throttle(() => store.getState(), 1000));
setupListeners(store.dispatch);
