import React, { Fragment } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useStyles from "./sidebarBig-jss";
import MainMenuBig from "./MainMenuBig";

function SidebarBig(props) {
  const { classes } = useStyles();
  const { dataMenu, loadTransition, open, toggleDrawerOpen } = props;

  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <Fragment>
      {!lgUp && (
        <SwipeableDrawer
          onClose={toggleDrawerOpen}
          onOpen={toggleDrawerOpen}
          open={!open}
          anchor="left"
        >
          <div className={classes.swipeDrawerPaper}>
            <MainMenuBig
              dataMenu={dataMenu}
              loadTransition={loadTransition}
              drawerPaper
              toggleDrawerOpen={toggleDrawerOpen}
              mobile
            />
          </div>
        </SwipeableDrawer>
      )}
      {!lgDown && (
        <div>
          <MainMenuBig
            dataMenu={dataMenu}
            loadTransition={loadTransition}
            drawerPaper={open}
          />
        </div>
      )}
    </Fragment>
  );
}

SidebarBig.propTypes = {
  dataMenu: PropTypes.array,
  loadTransition: PropTypes.func,
  toggleDrawerOpen: PropTypes.func,
  open: PropTypes.bool
};

export default SidebarBig;
