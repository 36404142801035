const lightPalette = {
  greyTheme: {
    palette: {
      primary: {
        light: "#ECEFF1",
        main: "#607D8B",
        dark: "#455A64",
        contrastText: "#fff"
      },
      secondary: {
        light: "#E0E0E0",
        main: "#757575",
        dark: "#424242",
        contrastText: "#fff"
      }
    }
  },
  // blueCyanTheme: {
  //   palette: {
  //     primary: {
  //       light: "#a9e677",
  //       main: "#81cf42",
  //       dark: "#61c213",
  //       contrastText: "#fff"
  //     },
  //     secondary: {
  //       light: "#def6cb",
  //       main: "#99c476",
  //       dark: "#6d9c48",
  //       contrastText: "#fff"
  //     }
  //   }
  // }
  blueCyanTheme: {
    palette: {
      primary: {
        light: "#eaeef5",
        main: "#2f569d",
        dark: "#25447d",
        contrastText: "#fff"
      },
      secondary: {
        light: "#ebeeaa",
        main: "#d8dd55",
        dark: "#6c6e2a",
        contrastText: "#fff"
      }
    }
  }
};

export default lightPalette;
