import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import useStyles from "./header-jss";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

// eslint-disable-next-line
function MegaMenu(props) {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState([]);
  const [openMenu, setOpenMenu] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const { open } = props;
    setTimeout(() => {
      setActive(open);
    }, 50);
  }, []);

  const handleOpenMenu = (event, key, keyParent) => {
    const { openSubMenu } = props;
    openSubMenu(key, keyParent);
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setOpenMenu([key]);
    }, 50);
  };

  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setOpenMenu([]);
  };

  const handleActiveParent = (key) => {
    setActive([key]);
    setOpenMenu([]);
  };

  const { open, dataMenu } = props;
  const getMenus = (parent, menuArray) =>
    menuArray?.map((item, index) => {
      // console.log("itemmmm",item)
      if (item.multilevel) {
        return false;
      }
      if (item.linkParent) {
        return (
          <div key={index.toString()}>
            <Button
              aria-haspopup="true"
              component={LinkBtn}
              to={item.linkParent ? item.linkParent : "#"}
              className={cx(
                classes.headMenu,
                open.indexOf(item.key) > -1 ? classes.opened : "",
                active.indexOf(item.key) > -1 ? classes.selected : ""
              )}
              onClick={() => navigate(item.linkParent)}
            >
              {item.name}
              {item.child ? (
                <ExpandMore className={classes.rightIcon} />
              ) : (
                <span className={classes.rightIcon}>&nbsp;&nbsp;</span>
              )}
            </Button>
            {item.child && (
              <Popper
                open={openMenu.indexOf(item.key) > -1}
                anchorEl={anchorEl}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Fade
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom"
                    }}
                  >
                    <Paper className={classes.dropDownMenu}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <Grid container>
                          <Grid item md={3} container justifyContent="center">
                            <span className={classes.bigIcon}>
                              <i className={cx(classes.icon, item.icon)} />
                            </span>
                          </Grid>
                          <Grid item md={9}>
                            <List
                              role="menu"
                              component="nav"
                              className={classes.megaMenu}
                            >
                              {getMenus(item.key, item.child)}
                            </List>
                          </Grid>
                        </Grid>
                      </ClickAwayListener>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            )}
          </div>
        );
      }
      if (item.link) {
        return (
          <ListItem
            key={`${item.key}-${index.toString()}`}
            className={classes.megaItem}
            activeClassName={classes.active}
            component={LinkBtn}
            onClick={() => navigate(item.link)}
          >
            <ListItemText primary={item.name} />
          </ListItem>
        );
      }
    });
  return (
    <nav className={classes.mainMenu}>
      <div className={classes.megaMenu}>{getMenus(null, dataMenu)}</div>
    </nav>
  );
}

MegaMenu.propTypes = {
  open: PropTypes.array,
  openSubMenu: PropTypes.func,
  dataMenu: PropTypes.array
};

const openAction = (key, keyParent) => ({
  type: "OPEN_SUBMENU",
  key,
  keyParent
});

const mapStateToProps = (state) => ({
  open: state.rootReducer.ui.subMenuOpen
});

const mapDispatchToProps = (dispatch) => ({
  openSubMenu: bindActionCreators(openAction, dispatch)
});

const MegaMenuMapped = connect(mapStateToProps, mapDispatchToProps)(MegaMenu);

export default MegaMenuMapped;
