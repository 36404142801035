import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import brand from "../../assets/dummy/brand";
import useStyles from "./user-jss";
import { ContentDivider } from "../Divider";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import { loginCall } from "src/api_operations/actions";
import { Form, FormikProvider, useFormik } from "formik";
import { Box, Checkbox, FormLabel, TextField } from "@mui/material";
import Validations from "../../utils/formValidation";
import {
  getAllPermissions,
  getProfileData,
  getRolesList,
  getUserPermissions,
  getUsersList,
} from "src/redux/actions/Operations";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function LoginForm(props) {
  const { classes, cx } = useStyles();
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { deco } = props;

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const onSubmit = (value) => {
    const data = {
      Email: value.email,
      password: value.password,
      source: "web",
    };
    props.loginCall(
      data,
      (res) => {
        localStorage.setItem("UserType", res.UserType);
        localStorage.setItem("UserId", res.UserId);
        localStorage.setItem("EID", res.EID);
        localStorage.setItem("UserId", res.UserId);
        if (res.status === true) {
          login(res.token);
          props.getAllPermissions();
          props.getRolesList();
          props.getUsersList();
          props.getUserPermissions();
          props.getProfileData({ UserId: res.UserId });
        } else {
          enqueueSnackbar(`${res.message}`, {
            variant: "error",
          });
        }
      },
      (err) =>
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        })
    );
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // remember: true
    },
    validationSchema: Validations.LoginSchema,
    onSubmit: onSubmit,
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <Fragment>
      <Paper className={cx(classes.paperWrap, deco && classes.petal)}>
        {!mdDown && (
          <div className={classes.topBar}>
            <NavLink
              to="/"
              className={classes.brand}
              sx={{
                color: "white", // Set text color to white
                textDecoration: "none", // Optional: Remove underline
                display: "flex", // If you want the image and text to be aligned
                alignItems: "center",
              }}
            >
              <img src={"/images/logo.svg"} alt={brand.name} />
              {brand.name}
            </NavLink>
            {/* <Button
              size="small"
              className={classes.buttonLink}
              component={LinkBtn}
              to="/register"
            >
              Create new account
            </Button> */}
          </div>
        )}
        {!mdUp && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <NavLink to="/" className={cx(classes.brand, classes.outer)}>
              <img src={"/images/logo.svg"} alt={brand.name} />
              {brand.name}
            </NavLink>
            {/* <Button
              size="small"
              className={classes.buttonLink}
              component={LinkBtn}
              to="/register"
            >
              Create new account
            </Button> */}
          </Box>
        )}
        {/* <Typography variant="h4" className={classes.title} gutterBottom>
          Accept My Invoice
        </Typography> */}
        <section className={classes.socmedLogin}>
          <ContentDivider content="Welcome back" />
        </section>
        <section className={classes.formWrap}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <div>
                <FormControl variant="standard" className={classes.formControl}>
                  <FormLabel
                    sx={{
                      color: "white", // Set text color to white
                    }}
                  >
                    Email<span>*</span>
                  </FormLabel>
                  <TextField
                    name="email"
                    variant="standard"
                    {...getFieldProps("email")}
                    required
                    className={classes.field}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl variant="standard" className={classes.formControl}>
                  <FormLabel
                    sx={{
                      color: "white", // Set text color to white
                    }}
                  >
                    Password<span>*</span>
                  </FormLabel>
                  <TextField
                    name="password"
                    variant="standard"
                    {...getFieldProps("password")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="large"
                            sx={{
                              color: "white", // Set text color to white
                            }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                    className={classes.field}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </FormControl>
              </div>
              <div
                className={classes.optArea}
                style={{ justifyContent: "flex-end" }}
              >
                {/* <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox name="remember" {...getFieldProps("remember")} />
                  }
                  label="Remember"
                /> */}
                <Button
                  size="small"
                  component={LinkBtn}
                  to="/reset-password"
                  className={classes.buttonLink}
                  sx={{
                    color: "white", // Set text color to white
                  }}
                >
                  Forgot Password
                </Button>
              </div>
              <div className={classes.btnArea}>
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  // disabled={isSubmitting}
                > */}
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  // disabled={isSubmitting}
                  sx={{
                    backgroundColor: "#ffbd59",
                    "&:hover": {
                      backgroundColor: "#e6a747", // Optional: Darken on hover
                    },
                  }}
                >
                  Login
                </Button>
              </div>
            </Form>
          </FormikProvider>
        </section>
      </Paper>
    </Fragment>
  );
}

LoginForm.propTypes = {
  loginCall: PropTypes.func,
  getAllPermissions: PropTypes.func,
  getRolesList: PropTypes.func,
  getUserPermissions: PropTypes.func,
  getUsersList: PropTypes.func,
  getProfileData: PropTypes.func,
};

LoginForm.defaultProps = {
  loginCall: () => {},
};

const mapStateToProps = (state) => ({
  force: state.rootReducer,
  deco: state.rootReducer.ui.decoration,
});

export default connect(mapStateToProps, {
  loginCall,
  getAllPermissions,
  getRolesList,
  getUserPermissions,
  getUsersList,
  getProfileData,
})(LoginForm);
