// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./styles/layout/base.scss";
// Import root app
import App from "./containers/App";
import { store } from "./redux/configureStore";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
// Create redux store with history
const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);
root.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    preventDuplicate
    autoHideDuration={2000}
  >
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </SnackbarProvider>
);
