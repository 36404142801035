import * as types from "./actionTypes";
import * as urls from "src/utils/urls";
import { post, get, put } from "./httpMethods";

export const getRolesList = (data) => (dispatch) => {
  dispatch({ type: types.ROLES_LIST });
  get({
    url: urls.ROLES_LIST,
    success: types.ROLES_LIST_SUCCESS,
    failure: types.ROLES_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getRoleData = (data) => (dispatch) => {
  dispatch({ type: types.ROLE_BY_ID });
  get({
    url: urls.ROLE_BY_ID,
    success: types.ROLE_BY_ID_SUCCESS,
    failure: types.ROLE_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const getRoleAssociatedUsers = (data) => (dispatch) => {
  dispatch({ type: types.ROLES_ASSOCIATED_USERS });
  get({
    url: urls.ROLE_ASSOCIATED_USERS,
    success: types.ROLES_ASSOCIATED_USERS_SUCCESS,
    failure: types.ROLES_ASSOCIATED_USERS_ERROR,
    dispatch,
    body: data
  });
};

export const getAllPermissions = (data) => (dispatch) => {
  dispatch({ type: types.ALL_PERMISSIONS });
  get({
    url: urls.ALL_PERMISSIONS,
    success: types.ALL_PERMISSIONS_SUCCESS,
    failure: types.ALL_PERMISSIONS_ERROR,
    dispatch,
    body: data
  });
};

export const getUserPermissions = (data) => (dispatch) => {
  dispatch({ type: types.USER_PERMISSIONS });
  get({
    url: urls.USER_PERMISSIONS,
    success: types.USER_PERMISSIONS_SUCCESS,
    failure: types.USER_PERMISSIONS_ERROR,
    dispatch,
    body: data
  });
};

export const getUsersList = (data) => (dispatch) => {
  dispatch({ type: types.USERS_LIST });
  get({
    url: urls.USERS_LIST,
    success: types.USERS_LIST_SUCCESS,
    failure: types.USERS_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getUserDatabyId = (data) => (dispatch) => {
  dispatch({ type: types.USERDATA_BY_ID });
  get({
    url: urls.USER_BY_ID,
    success: types.USERDATA_BY_ID_SUCCESS,
    failure: types.USERDATA_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const getProfileData = (data) => (dispatch) => {
  dispatch({ type: types.PROFILE_DATA });
  get({
    url: urls.USER_BY_ID,
    success: types.PROFILE_DATA_SUCCESS,
    failure: types.PROFILE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const getBuyerList = (data) => (dispatch) => {
  dispatch({ type: types.BUYER_LIST });
  get({
    url: urls.BUYER_SELLERS_LIST,
    success: types.BUYER_LIST_SUCCESS,
    failure: types.BUYER_LIST_ERROR,
    dispatch,
    body: data
  });
};
export const getBuyerListForInvoiceType = (data) => (dispatch) => {
  dispatch({ type: types.BUYER_LIST_INVOICE });
  get({
    url: urls.BUYER_SELLERS_LIST_INVOICE,
    success: types.BUYER_LIST_INVOICE_SUCCESS,
    failure: types.BUYER_LIST_INVOICE_ERROR,
    dispatch,
    body: data
  });
};

export const getInvoiceList = (data) => (dispatch) => {
  dispatch({ type: types.INVOICE_LIST });
  get({
    url: urls.INVOICE_LIST,
    success: types.INVOICE_LIST_SUCCESS,
    failure: types.INVOICE_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getDashboardData = (data) => (dispatch) => {
  dispatch({ type: types.DASHBOARD_DATA });
  get({
    url: urls.DASHBOARD_DATA,
    success: types.DASHBOARD_DATA_SUCCESS,
    failure: types.DASHBOARD_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const getInvoiceAcceptanceList = (data) => (dispatch) => {
  dispatch({ type: types.INVOICE_ACCEPTANCE_LIST });
  get({
    url: urls.INVOICE_ACCEPTANCE_LIST,
    success: types.INVOICE_ACCEPTANCE_SUCCESS,
    failure: types.INVOICE_ACCEPTANCE_ERROR,
    dispatch,
    body: data
  });
};

export const getBuyerListbyId = (data) => (dispatch) => {
  dispatch({ type: types.BUYER_BY_ID });
  get({
    url: urls.BUYER_SELLERS_LIST_BY_ID,
    success: types.BUYER_BY_ID_SUCCESS,
    failure: types.BUYER_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const getSellersList = (data) => (dispatch) => {
  dispatch({ type: types.SELLER_LIST });
  get({
    url: urls.BUYER_SELLERS_LIST,
    success: types.SELLER_LIST_SUCCESS,
    failure: types.SELLER_LIST_ERROR,
    dispatch,
    body: { EntityType: "s" }
  });
};

export const getSellersListByEID = (data) => (dispatch) => {
  dispatch({ type: types.SELLER_LIST_EID });
  get({
    url: urls.BUYER_SELLERS_LIST,
    success: types.SELLER_LIST_EID_SUCCESS,
    failure: types.SELLER_LIST_EID_ERROR,
    dispatch,
    body: data
  });
};

export const getBuyersListByEID = (data) => (dispatch) => {
  dispatch({ type: types.BUYER_LIST_EID });
  get({
    url: urls.BUYER_SELLERS_LIST,
    success: types.BUYER_LIST_EID_SUCCESS,
    failure: types.BUYER_LIST_EID_ERROR,
    dispatch,
    body: data
  });
};

export const getSellersListForInvoiceType = (data) => (dispatch) => {
  dispatch({ type: types.SELLER_LIST_INVOICE });
  get({
    url: urls.BUYER_SELLERS_LIST_INVOICE,
    success: types.SELLER_LIST_INVOICE_SUCCESS,
    failure: types.SELLER_LIST_INVOICE_ERROR,
    dispatch,
    body: data
  });
};

export const getSellersListbyId = (data) => (dispatch) => {
  dispatch({ type: types.SELLER_BY_ID });
  get({
    url: urls.BUYER_SELLERS_LIST_BY_ID,
    success: types.SELLER_BY_ID_SUCCESS,
    failure: types.SELLER_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const getEntitiesList = (data) => (dispatch) => {
  dispatch({ type: types.ENTITIES_LIST });
  get({
    url: urls.ALL_ENTITY_LIST,
    success: types.ENTITIES_LIST_SUCCESS,
    failure: types.ENTITIES_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const emptyAssociatedUsers = () => (dispatch) => {
  dispatch({ type: types.EMPTY_ASSOCIATED_USERS, body: false });
};

export const getFirstPartyGSTINList = (body) => (dispatch) => {
  dispatch({ type: types.FIRST_PARTY_GSTIN_LIST });
  get({
    url: urls.BUYER_SELLERS_LIST_INVOICE,
    success: types.FIRST_PARTY_GSTIN_LIST_SUCCESS,
    failure: types.FIRST_PARTY_GSTIN_LIST_ERROR,
    dispatch,
    body: body
  });
};

export const getSecondPartyGSTINList = (body) => (dispatch) => {
  dispatch({ type: types.SECOND_PARTY_GSTIN_LIST });
  get({
    url: urls.BUYER_SELLERS_LIST_INVOICE,
    success: types.SECOND_PARTY_LIST_GSTIN_SUCCESS,
    failure: types.SECOND_PARTY_GSTIN_LIST_ERROR,
    dispatch,
    body: body
  });
};

export const getInvoiceListOcr = (data) => (dispatch) => {
  dispatch({ type: types.INVOICE_LIST_OCR });
  get({
    url: urls.INVOICE_LIST_OCR,
    success: types.INVOICE_LIST_OCR_SUCCESS,
    failure: types.INVOICE_LIST_OCR_ERROR,
    dispatch,
    body: data
  });
};

export const getInvoiceListLogs = (data) => (dispatch) => {
  dispatch({ type: types.INVOICE_LIST_LOGS });
  get({
    url: urls.INVOICE_LIST_LOGS,
    success: types.INVOICE_LIST_LOGS_SUCCESS,
    failure: types.INVOICE_LIST_LOGS_ERROR,
    dispatch,
    body: data
  });
};

export const getMisList = (data) => (dispatch) => {
  dispatch({ type: types.GET_MIS_LIST });
  get({
    url: urls.GET_MIS_LIST,
    success: types.GET_MIS_LIST_SUCCESS,
    failure: types.GET_MIS_LIST_ERROR,
    dispatch,
    body: data
  });
};
export const getPODTable = (data) => (dispatch) => {
  dispatch({ type: types.GET_POD_TABLE });
  get({
    url: urls.GET_POD_TABLE,
    success: types.GET_POD_TABLE_SUCCESS,
    failure: types.GET_POD_TABLE_ERROR,
    dispatch,
    body: data
  });
};

export const getBuyerListInvoice = (data) => (dispatch) => {
  dispatch({ type: types.GET_BUYER_LIST });
  get({
    url: urls.GET_BUYER_LIST,
    success: types.GET_BUYER_LIST_SUCCESS,
    failure: types.GET_BUYER_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getBuyerSellerListAutoPopulate = (data) => (dispatch) => {
  dispatch({ type: types.BUYER_SELLERS_LIST_AUTO_POPULATE });
  get({
    url: urls.BUYER_SELLERS_LIST_AUTO_POPULATE,
    success: types.BUYER_SELLERS_LIST_AUTO_POPULATE_SUCCESS,
    failure: types.BUYER_SELLERS_LIST_AUTO_POPULATE_ERROR,
    dispatch,
    body: data
  });
};
export const getEmailCheckAutoPopulate = (data) => (dispatch) => {
  dispatch({ type: types.GET_EMAIL_CHECK });
  get({
    url: urls.GET_EMAIL_CHECK,
    success: types.GET_EMAIL_CHECK_SUCCESS,
    failure: types.GET_EMAIL_CHECK_ERROR,
    dispatch,
    body: data
  });
};

export const getBuyerSellerListAutoPopulateGSTIN = (data) => (dispatch) => {
  dispatch({ type: types.BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN });
  get({
    url: urls.BUYER_SELLERS_LIST_AUTO_POPULATE,
    success: types.BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN_SUCCESS,
    failure: types.BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN_ERROR,
    dispatch,
    body: data
  });
};

export const getBADValidate = (data) => (dispatch) => {
  dispatch({ type: types.BANK_ACCOUNT_DETAILS_VALIDATE });
  get({
    url: urls.BUYER_SELLERS_LIST_AUTO_POPULATE,
    success: types.BANK_ACCOUNT_DETAILS_VALIDATE_SUCCESS,
    failure: types.BANK_ACCOUNT_DETAILS_VALIDATE_ERROR,
    dispatch,
    body: data
  });
};


export const getInvoiceVerificationSendList = (data) => (dispatch) => {
  dispatch({ type: types.INVOICE_VERIFICATION_SEND_LIST });
  get({
    url: urls.INVOICE_VERIFICATION_SEND_LIST,
    success: types.INVOICE_VERIFICATION_SEND_LIST_SUCCESS,
    failure: types.INVOICE_VERIFICATION_SEND_LIST_ERROR,
    dispatch,
    body: data
  });
};


export const getEWaybillList = (data) => (dispatch) => {
  dispatch({ type: types.INVOICE_EWAY_BILL_LIST });
  get({
    url: urls.INVOICE_EWAY_BILL_LIST,
    success: types.INVOICE_EWAY_BILL_LIST_SUCCESS,
    failure: types.INVOICE_EWAY_BILL_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getConsentLinkData = (data) => (dispatch) => {
  dispatch({ type: types.SEND_CONSENT_LINK });
  get({
    url: urls.SEND_CONSENT_LINK,
    success: types.SEND_CONSENT_LINK_SUCCESS,
    failure: types.SEND_CONSENT_LINK_ERROR,
    dispatch,
    body: data
  });
};

export const getRoleType = (data) => (dispatch) => {
  dispatch({ type: types.ROLE_TYPE_LIST });
  get({
    url: urls.ROLE_TYPE_LIST,
    success: types.ROLE_TYPE_LIST_SUCCESS,
    failure: types.ROLE_TYPE_LIST_ERROR,
    dispatch,
    body: data
  });
};


export const getRoleListById = (data) => (dispatch) => {
  dispatch({ type: types.ROLE_LIST_BY_ID });
  get({
    url: urls.ROLE_LIST_BY_ID,
    success: types.ROLE_LIST_BY_ID_SUCCESS,
    failure: types.ROLE_LIST_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const getRolePageListById = (data) => (dispatch) => {
  dispatch({ type: types.ROLE_PAGES_LIST_BY_ID });
  get({
    url: urls.ROLE_PAGES_LIST_BY_ID,
    success: types.ROLE_PAGES_LIST_BY_ID_SUCCESS,
    failure: types.ROLE_PAGES_LIST_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const getEntityList = (body) => (dispatch) => {
  dispatch({ type: types.ENTITY_LIST });
  get({
    url: urls.ENTITY_LIST,
    success: types.ENTITY_LIST_SUCCESS,
    failure: types.ENTITY_LIST_ERROR,
    dispatch,
    body: body
  });
};

export const getPODList = (body) => (dispatch) => {
  dispatch({ type: types.POD_LIST });
  get({
    url: urls.POD_LIST,
    success: types.POD_LIST_SUCCESS,
    failure: types.POD_LIST_ERROR,
    dispatch,
    body: body
  });
};

export const getPODData = (body) => (dispatch) => {
  dispatch({ type: types.POD_DATA });
  get({
    url: urls.POD_LIST,
    success: types.POD_DATA_SUCCESS,
    failure: types.POD_DATA_ERROR,
    dispatch,
    body: body
  });
};

export const updatePOD = (body) => (dispatch) => {
  dispatch({ type: types.POD_UPDATE });
  put({
    url: urls.POD_UPDATE,
    success: types.POD_UPDATE_SUCCESS,
    failure: types.POD_UPDATE_ERROR,
    dispatch,
    body: body
  });
};