// Main Components
import HeaderComponent from "./Header/Header";
import HeaderMenuComponent from "./Header/HeaderMenu";
import SidebarComponent from "./Sidebar";
import SidebarBigComponent from "./SidebarBig";
import BreadCrumbComponent from "./BreadCrumb/BreadCrumb";
import PapperBlockComponent from "./PapperBlock/PapperBlock";
// Form
import LoginFormComponent from "./Forms/LoginForm";
import RegisterFormComponent from "./Forms/RegisterForm";
import ResetFormComponent from "./Forms/ResetForm";
import NotificationComponent from "./Notification/Notification";
// Table
import EmptyDataComponent from "./Tables/EmptyData";
// Error
import ErrorWrapComponent from "./Error/ErrorWrap";

// Main Components
export const Header = (props) => <HeaderComponent {...props} />;
export const HeaderMenu = (props) => <HeaderMenuComponent {...props} />;
export const Sidebar = (props) => <SidebarComponent {...props} />;
export const SidebarBig = (props) => <SidebarBigComponent {...props} />;
export const BreadCrumb = (props) => <BreadCrumbComponent {...props} />;
export const PapperBlock = (props) => <PapperBlockComponent {...props} />;
// Form
export const LoginForm = (props) => <LoginFormComponent {...props} />;
export const RegisterForm = (props) => <RegisterFormComponent {...props} />;
export const ResetForm = (props) => <ResetFormComponent {...props} />;
export const Notification = (props) => <NotificationComponent {...props} />;
// Table
export const EmptyData = (props) => <EmptyDataComponent {...props} />;
// Error
export const ErrorWrap = (props) => <ErrorWrapComponent {...props} />;
