import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import Outer from "../Templates/Outer";
import { useAuth } from "src/hooks/useAuth";
import ResponsiveAppBar from "src/components/Navbar";
import useStyles from "./authStyles";
import { Box, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function Auth() {
  const { user } = useAuth();
  const outlet = useOutlet();
  const { classes, cx } = useStyles();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  if (user) {
    return <Navigate to="/app" replace />;
  }
  return (
    <Outer>
      <ResponsiveAppBar />
      <Grid container>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: mdUp ? "" : "fit-content" }}
        >
          <Box>
            <Typography variant="h3" className={classes.authFrameOuter}>
              Your one-stop solution for
              <br />
              <span className="underline-word">Invoice Acceptance,</span>
              <br />
              and Receivable Management
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          justifyContent="center"
          display="flex"
          style={{ height: mdUp ? "" : "fit-content" }}
        >
          {outlet}
        </Grid>
      </Grid>
    </Outer>
  );
}

export default Auth;
