import storage from "redux-persist/lib/storage"; // Use local storage
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "ui", // Change this key to a unique identifier
  storage,
  whitelist: ["ui", "permissions"] // List the reducers you want to persist
};

export default (reducers) => persistReducer(persistConfig, reducers);
