import React from "react";
import PropTypes from "prop-types";

function PapperBlock(props) {
  const { children } = props;
  return <>{children}</>;
}

PapperBlock.propTypes = {
  children: PropTypes.node
};

PapperBlock.defaultProps = {};

export default PapperBlock;
