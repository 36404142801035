import * as types from "../actions/actionTypes";
import { initialState } from "./initialState";

export const app = (state = initialState.app, action) => {
  switch (action.type) {
    // get all roles list
    case types.ROLES_LIST:
      return {
        ...state,
        rolesList: false,
        loading: true,
        error: false,
      };
    case types.ROLES_LIST_SUCCESS:
      return {
        ...state,
        rolesList: action.data,
        loading: false,
        error: false,
      };
    case types.ROLES_LIST_ERROR:
      return { ...state, rolesList: false, loading: false, error: true };
    // get particular role data
    case types.ROLE_BY_ID:
      return {
        ...state,
        roleData: false,
        loading: true,
        error: false,
      };
    case types.ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        roleData: action.data,
        loading: false,
        error: false,
      };
    case types.ROLE_BY_ID_ERROR:
      return { ...state, roleData: false, loading: false, error: true };

    // get all users list
    case types.USERS_LIST:
      return {
        ...state,
        users: false,
        loading: true,
        error: false,
      };
    case types.USERS_LIST_SUCCESS:
      return {
        ...state,
        users: action.data,
        loading: false,
        error: false,
      };
    case types.USERS_LIST_ERROR:
      return { ...state, users: false, loading: false, error: true };
    // get particular user data
    case types.USERDATA_BY_ID:
      return {
        ...state,
        userDataById: false,
        loading: true,
        error: false,
      };
    case types.USERDATA_BY_ID_SUCCESS:
      return {
        ...state,
        userDataById: action.data,
        loading: false,
        error: false,
      };
    case types.USERDATA_BY_ID_ERROR:
      return { ...state, userDataById: false, loading: false, error: true };
    // get roles associated users list
    case types.ROLES_ASSOCIATED_USERS:
      return {
        ...state,
        associateUsers: false,
        loading: true,
        error: false,
      };
    case types.ROLES_ASSOCIATED_USERS_SUCCESS:
      return {
        ...state,
        associateUsers: action.data,
        loading: false,
        error: false,
      };
    case types.ROLES_ASSOCIATED_USERS_ERROR:
      return {
        ...state,
        associateUsers: false,
        loading: true,
        error: false,
      };
    // get sellers list
    case types.SELLER_LIST:
      return {
        ...state,
        mySellers: false,
        loading: true,
        error: false,
      };
    case types.SELLER_LIST_SUCCESS:
      return {
        ...state,
        mySellers: action.data,
        loading: false,
        error: false,
      };
    case types.SELLER_LIST_ERROR:
      return {
        ...state,
        mySellers: false,
        loading: true,
        error: false,
      };

    // get sellers list by EID
    case types.SELLER_LIST_EID:
      return {
        ...state,
        mySellersEID: false,
        loading: true,
        error: false,
      };
    case types.SELLER_LIST_EID_SUCCESS:
      return {
        ...state,
        mySellersEID: action.data,
        loading: false,
        error: false,
      };
    case types.SELLER_LIST_EID_ERROR:
      return {
        ...state,
        mySellersEID: false,
        loading: true,
        error: false,
      };
    // get buyers list by EID
    case types.BUYER_LIST_EID:
      return {
        ...state,
        myBuyersEID: false,
        loading: true,
        error: false,
      };
    case types.BUYER_LIST_EID_SUCCESS:
      return {
        ...state,
        myBuyersEID: action.data,
        loading: false,
        error: false,
      };
    case types.BUYER_LIST_EID_ERROR:
      return {
        ...state,
        myBuyersEID: false,
        loading: true,
        error: false,
      };

    // get sellers list invoice
    case types.SELLER_LIST_INVOICE:
      return {
        ...state,
        mySellersInvoiceType: false,
        loading: true,
        error: false,
      };
    case types.SELLER_LIST_INVOICE_SUCCESS:
      return {
        ...state,
        mySellersInvoiceType: action.data,
        loading: false,
        error: false,
      };
    case types.SELLER_LIST_INVOICE_ERROR:
      return {
        ...state,
        mySellersInvoiceType: false,
        loading: true,
        error: false,
      };

    // get sellers by Id list
    case types.SELLER_BY_ID:
      return {
        ...state,
        sellerDataById: false,
        loading: true,
        error: false,
      };
    case types.SELLER_BY_ID_SUCCESS:
      return {
        ...state,
        sellerDataById: action.data,
        loading: false,
        error: false,
      };
    case types.SELLER_BY_ID_ERROR:
      return {
        ...state,
        sellerDataById: false,
        loading: true,
        error: false,
      };
    // get buyers list
    case types.BUYER_LIST:
      return {
        ...state,
        myBuyers: false,
        loading: true,
        error: false,
      };
    case types.BUYER_LIST_SUCCESS:
      return {
        ...state,
        myBuyers: action.data,
        loading: false,
        error: false,
      };
    case types.BUYER_LIST_ERROR:
    case types.INVOICE_ACCEPTANCE_LIST:
      return {
        ...state,
        invoiceAcceptanceListData: false,
        loading: true,
        error: false,
      };
    case types.INVOICE_ACCEPTANCE_SUCCESS:
      return {
        ...state,
        invoiceAcceptanceListData: action.data,
        loading: false,
        error: false,
      };
    case types.INVOICE_ACCEPTANCE_ERROR:
      return {
        ...state,
        invoiceAcceptanceListData: false,
        loading: true,
        error: false,
      };

    // get buyers list invoice type
    case types.BUYER_LIST_INVOICE:
      return {
        ...state,
        myBuyersInvoiceType: false,
        loading: true,
        error: false,
      };
    case types.BUYER_LIST_INVOICE_SUCCESS:
      return {
        ...state,
        myBuyersInvoiceType: action.data,
        loading: false,
        error: false,
      };
    case types.BUYER_LIST_INVOICE_ERROR:
      return {
        ...state,
        myBuyersInvoiceType: false,
        loading: true,
        error: false,
      };

    case types.INVOICE_VERIFICATION_SEND_LIST:
      return {
        ...state,
        invoiceVerificationListData: false,
        loading: true,
        error: false,
      };
    case types.INVOICE_VERIFICATION_SEND_LIST_SUCCESS:
      return {
        ...state,
        invoiceVerificationListData: action.data,
        loading: false,
        error: false,
      };
    case types.INVOICE_VERIFICATION_SEND_LIST_ERROR:
      return {
        ...state,
        invoiceVerificationListData: false,
        loading: true,
        error: false,
      };
    case types.INVOICE_LIST:
      return {
        ...state,
        invoiceListData: false,
        loading: true,
        error: false,
      };
    case types.INVOICE_LIST_SUCCESS:
      return {
        ...state,
        invoiceListData: action.data,
        loading: false,
        error: false,
      };
    case types.INVOICE_LIST_ERROR:
      return {
        ...state,
        myBuinvoiceListDatayers: false,
        loading: true,
        error: false,
      };
    case types.DASHBOARD_DATA:
      return {
        ...state,
        DashboardData: false,
        loading: true,
        error: false,
      };
    case types.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        DashboardData: action.data,
        loading: false,
        error: false,
      };
    case types.DASHBOARD_DATA_ERROR:
      return {
        ...state,
        DashboardData: false,
        loading: true,
        error: false,
      };
    case types.SEND_CONSENT_LINK:
      return {
        ...state,
        sendConsentLinkData: false,
        loading: true,
        error: false,
      };
    case types.SEND_CONSENT_LINK_SUCCESS:
      return {
        ...state,
        sendConsentLinkData: action.data,
        loading: false,
        error: false,
      };
    case types.SEND_CONSENT_LINK_ERROR:
      return {
        ...state,
        sendConsentLinkData: false,
        loading: true,
        error: false,
      };
    case types.ROLE_TYPE_LIST:
      return {
        ...state,
        roleTypeData: false,
        loading: true,
        error: false,
      };
    case types.ROLE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        roleTypeData: action.data,
        loading: false,
        error: false,
      };
    case types.ROLE_TYPE_LIST_ERROR:
      return {
        ...state,
        roleTypeData: false,
        loading: true,
        error: false,
      };
    case types.ROLE_LIST_BY_ID:
      return {
        ...state,
        roleListById: false,
        loading: false,
        error: false,
      };
    case types.ROLE_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        roleListById: action.data,
        loading: false,
        error: false,
      };
    case types.ROLE_LIST_BY_ID_ERROR:
      return {
        ...state,
        roleListById: false,
        loading: false,
        error: false,
      };
    case types.ROLE_PAGES_LIST_BY_ID:
      return {
        ...state,
        rolePageListById: false,
        loading: false,
        error: false,
      };
    case types.ROLE_PAGES_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        rolePageListById: action.data,
        loading: false,
        error: false,
      };
    case types.ROLE_PAGES_LIST_BY_ID_ERROR:
      return {
        ...state,
        rolePageListById: false,
        loading: false,
        error: false,
      };
    case types.INVOICE_LIST_OCR:
      return {
        ...state,
        invoiceListOcrData: false,
        loading: true,
        error: false,
      };
    case types.INVOICE_LIST_OCR_SUCCESS:
      return {
        ...state,
        invoiceListOcrData: action.data,
        loading: false,
        error: false,
      };
    case types.INVOICE_LIST_OCR_ERROR:
      return {
        ...state,
        invoiceListOcrData: false,
        loading: true,
        error: false,
      };
    case types.INVOICE_LIST_LOGS:
      return {
        ...state,
        invoiceListLogsData: false,
        loading: true,
        error: false,
      };
    case types.INVOICE_LIST_LOGS_SUCCESS:
      return {
        ...state,
        invoiceListLogsData: action.data,
        loading: false,
        error: false,
      };
    case types.INVOICE_LIST_LOGS_ERROR:
      return {
        ...state,
        invoiceListLogsData: false,
        loading: true,
        error: false,
      };
      case types.GET_MIS_LIST:
        return {
          ...state,
          misReport: false,
          loading: true,
          error: false,
        };
      case types.GET_MIS_LIST_SUCCESS:
        return {
          ...state,
          misReport: action.data,
          loading: false,
          error: false,
        };
      case types.GET_MIS_LIST_ERROR:
        return {
          ...state,
          misReport: false,
          loading: true,
          error: false,
        };
      case types.GET_BUYER_LIST:
        return {
          ...state,
          buyerListUploadInvoice: false,
          loading: true,
          error: false,
        };
      case types.GET_BUYER_LIST_SUCCESS:
        return {
          ...state,
          buyerListUploadInvoice: action.data,
          loading: false,
          error: false,
        };
      case types.GET_BUYER_LIST_ERROR:
        return {
          ...state,
          buyerListUploadInvoice: false,
          loading: true,
          error: false,
        };
    case types.INVOICE_EWAY_BILL_LIST:
      return {
        ...state,
        invoiceEWayBIllList: false,
        loading: true,
        error: false,
      };
    case types.INVOICE_EWAY_BILL_LIST_SUCCESS:
      return {
        ...state,
        invoiceEWayBIllList: action.data,
        loading: false,
        error: false,
      };
    case types.INVOICE_EWAY_BILL_LIST_ERROR:
      return {
        ...state,
        invoiceEWayBIllList: false,
        loading: true,
        error: false,
      };
    case types.BUYER_SELLERS_LIST_AUTO_POPULATE:
      return {
        ...state,
        buyerSellerAutoPopulateData: false,
        loading: true,
        error: false,
      };
    case types.BUYER_SELLERS_LIST_AUTO_POPULATE_SUCCESS:
      return {
        ...state,
        buyerSellerAutoPopulateData: action.data,
        loading: false,
        error: false,
      };
    case types.BUYER_SELLERS_LIST_AUTO_POPULATE_ERROR:
      return {
        ...state,
        buyerSellerAutoPopulateData: false,
        loading: true,
        error: false,
      };
      case types.GET_EMAIL_CHECK:
        return {
          ...state,
          emailCheckAutoPopulate: false,
          loading: true,
          error: false,
        };
      case types.GET_EMAIL_CHECK_SUCCESS:
        return {
          ...state,
          emailCheckAutoPopulate: action.data,
          loading: false,
          error: false,
        };
      case types.GET_EMAIL_CHECK_ERROR:
        return {
          ...state,
          emailCheckAutoPopulate: false,
          loading: true,
          error: false,
        };
    case types.BANK_ACCOUNT_DETAILS_VALIDATE:
      return {
        ...state,
        BADValidateData: false,
        loading: true,
        error: false,
      };
    case types.BANK_ACCOUNT_DETAILS_VALIDATE_SUCCESS:
      return {
        ...state,
        BADValidateData: action.data,
        loading: false,
        error: false,
      };
    case types.BANK_ACCOUNT_DETAILS_VALIDATE_ERROR:
      return {
        ...state,
        BADValidateData: false,
        loading: true,
        error: false,
      };
    case types.BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN:
      return {
        ...state,
        buyerSellerAutoPopulateGSTINData: false,
        loading: true,
        error: false,
      };
    case types.BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN_SUCCESS:
      return {
        ...state,
        buyerSellerAutoPopulateGSTINData: action.data,
        loading: false,
        error: false,
      };
    case types.BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN_ERROR:
      return {
        ...state,
        buyerSellerAutoPopulateGSTINData: false,
        loading: true,
        error: false,
      };
    case types.SEND_INVOICE_LIST:
      return {
        ...state,
        invoiceListEmailData: false,
        loading: true,
        error: false,
      };
    case types.SEND_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        invoiceListEmailData: action.data,
        loading: false,
        error: false,
      };
    case types.SEND_INVOICE_LIST_ERROR:
    case types.FIRST_PARTY_GSTIN_LIST:
      return {
        ...state,
        firstPartyGSTINData: false,
        loading: true,
        error: false,
      };
    case types.FIRST_PARTY_GSTIN_LIST_SUCCESS:
      return {
        ...state,
        firstPartyGSTINData: action.data,
        loading: false,
        error: false,
      };
    case types.FIRST_PARTY_GSTIN_LIST_ERROR:
      return {
        ...state,
        firstPartyGSTINData: false,
        loading: true,
        error: false,
      };
    case types.SECOND_PARTY_GSTIN_LIST:
      return {
        ...state,
        secondPartyGSTINData: false,
        loading: true,
        error: false,
      };
    case types.SECOND_PARTY_LIST_GSTIN_SUCCESS:
      return {
        ...state,
        secondPartyGSTINData: action.data,
        loading: false,
        error: false,
      };
    case types.SECOND_PARTY_GSTIN_LIST_ERROR:
      return {
        ...state,
        secondPartyGSTINData: false,
        loading: true,
        error: false,
      };
    // get sellers by Id list
    case types.BUYER_BY_ID:
      return {
        ...state,
        buyerDataById: false,
        loading: true,
        error: false,
      };
    case types.BUYER_BY_ID_SUCCESS:
      return {
        ...state,
        buyerDataById: action.data,
        loading: false,
        error: false,
      };
    case types.BUYER_BY_ID_ERROR:
      return {
        ...state,
        buyerDataById: false,
        loading: true,
        error: false,
      };
    case types.ENTITY_LIST:
      return {
        ...state,
        entityListData: false,
        loading: true,
        error: false,
      };
    case types.ENTITY_LIST_SUCCESS:
      return {
        ...state,
        entityListData: action.data,
        loading: false,
        error: false,
      };
    case types.ENTITY_LIST_ERROR:
      return {
        ...state,
        entityListData: false,
        loading: true,
        error: false,
      };
    case types.POD_LIST:
      return {
        ...state,
        PODListData: false,
        loading: true,
        error: false,
      };
    case types.POD_LIST_SUCCESS:
      return {
        ...state,
        PODListData: action.data,
        loading: false,
        error: false,
      };
    case types.POD_LIST_ERROR:
      return {
        ...state,
        PODListData: false,
        loading: true,
        error: false,
      };
      case types.POD_DATA:
        return {
          ...state,
          PODIdData: false,
          loading: true,
          error: false,
        };
      case types.POD_DATA_SUCCESS:
        return {
          ...state,
          PODIdData: action.data,
          loading: false,
          error: false,
        };
      case types.POD_DATA_ERROR:
        return {
          ...state,
          PODIdData: false,
          loading: true,
          error: false,
        };
      case types.POD_UPDATE:
        return {
          ...state,
          PODUpdateData: false,
          loading: true,
          error: false,
        };
      case types.POD_UPDATE_SUCCESS:
        return {
          ...state,
          PODUpdateData: action.data,
          loading: false,
          error: false,
        };
      case types.POD_UPDATE_ERROR:
        return {
          ...state,
          PODUpdateData: false,
          loading: true,
          error: false,
        };
    // associated users empty data
    case types.EMPTY_ASSOCIATED_USERS:
      return {
        ...state,
        associateUsers: false,
        loading: false,
        error: false,
      };
    case types.RESET_STATE:
      return initialState;
    // default
    default:
      return state;
  }
  return state;
};
