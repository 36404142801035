import produce from "immer";
import { INIT } from "../constants/reduxFormConstants";
import { appStates } from "./appInitialState";

/* eslint-disable default-case, no-param-reassign */
const loginReducer = (state = appStates, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case INIT:
        draft.login = state;
        break;
      default:
        break;
    }
  });

export default loginReducer;
