import React from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useStyles from "./sidebar-jss";
import { Link } from "@mui/material";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

// eslint-disable-next-line
function MainMenu(props) {
  const { openSubMenu, open, dataMenu } = props;
  const { classes, cx } = useStyles();

  const handleClick = () => {
    const { toggleDrawerOpen, loadTransition } = props;
    // navigate(to);
    toggleDrawerOpen();
    loadTransition(true);
  };

  const getMenus = (menuArray) =>
    menuArray?.map((item, index) => {
      if (item.linkParent && item.Permission) {
        return (
          <div key={index.toString()}>
            <ListItem
              component={LinkBtn}
              to={item.linkParent}
              className={cx(
                classes.head,
                item.icon ? classes.iconed : "",
                open.indexOf(item.key) > -1 ? classes.opened : ""
              )}
              onClick={() => handleClick()}
            >
              {item.icon && (
                <ListItemIcon className={classes.icon}>
                  {item.icon}
                </ListItemIcon>
              )}
              <ListItemText
                className={(classes.primary, classes.linkColor)}
                variant="inset"
                primary={item.name}
              />
            </ListItem>
          </div>
        );
      }
      if (item.child && item.Permission) {
        return (
          <div key={index.toString()}>
            <ListItem
              component={LinkBtn}
              className={cx(
                classes.head,
                item.icon ? classes.iconed : "",
                open.indexOf(item.key) > -1 ? classes.opened : ""
              )}
              onClick={() => openSubMenu(item.key, item.keyParent)}
            >
              {item.icon && (
                <ListItemIcon className={classes.icon}>
                  {item.icon}
                </ListItemIcon>
              )}
              <ListItemText
                className={(classes.primary, classes.linkColor)}
                variant="inset"
                primary={item.name}
              />
              {item.child && (
                <span>
                  {open.indexOf(item.key) > -1 ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </span>
              )}
            </ListItem>
            {item.child && (
              <Collapse
                component="div"
                className={cx(
                  classes.nolist,
                  item.keyParent ? classes.child : ""
                )}
                in={open.indexOf(item.key) > -1}
                timeout="auto"
                unmountOnExit
              >
                <List className={classes.dense} component="nav" dense>
                  {getMenus(item.child, item.key)}
                </List>
              </Collapse>
            )}
          </div>
        );
      }
      if (item.link) {
        return (
          <ListItem
            key={`${item.key}-${index.toString()}`}
            className={classes.nested}
            activeClassName={classes.active}
            component={LinkBtn}
            to={item.link}
            onClick={() => handleClick()}
          >
            <ListItemText
              className={(classes.primary, classes.linkColor)}
              inset
              primary={item.name}
            />
          </ListItem>
        );
      }
    });
  return <div>{getMenus(dataMenu)}</div>;
}

MainMenu.propTypes = {
  open: PropTypes.array,
  openSubMenu: PropTypes.func,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  dataMenu: PropTypes.array,
};

const openAction = (key, keyParent) => ({
  type: "OPEN_SUBMENU",
  key,
  keyParent,
});

const mapStateToProps = (state) => ({
  open: state.rootReducer.ui.subMenuOpen,
});

const mapDispatchToProps = (dispatch) => ({
  openSubMenu: bindActionCreators(openAction, dispatch),
});

const MainMenuMapped = connect(mapStateToProps, mapDispatchToProps)(MainMenu);

export default MainMenuMapped;
