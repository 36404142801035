import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HailIcon from "@mui/icons-material/Hail";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";


const UserType = localStorage.getItem("UserType");
const UserEID = localStorage.getItem("EID");
const dataMenu = [
  {
    key: "seller",
    name: `${UserType == "seller" ? "My GSTIN":"Sellers"}`,
    icon: <SensorOccupiedIcon />,
    linkParent: `${UserType == "seller" ? `/app/my-sellers/seller?id=${UserEID}`:"/app/my-sellers"}`
  },
  {
    key: "my-gstins",
    name: "My GSTIN",
    icon: <ReceiptLongIcon />,
    linkParent: `/app/my-sellers/seller?id=${UserEID}`
  },
  {
    key: "buyer",
    name: "My Buyers",
    icon: <HailIcon />,
    linkParent: "/app/my-buyers"
  },
  {
    key: "invoices",
    name: "Invoices",
    icon: <ReceiptIcon />,
    linkParent: "/app/invoices"
  },
  {
    key: "invoice-verification",
    name: "Invoice Verification",
    icon: <ReceiptLongIcon />,
    linkParent: "/app/invoice-verification"
  },
  {
    key: "invoice-acceptance",
    name: "Invoice Acceptance",
    icon: <ReceiptLongIcon />,
    linkParent: "/app/invoice-acceptance"
  },
  {
    key: "mis",
    name: "MIS",
    icon: <ReceiptLongIcon />,
    linkParent: "/app/mis"
  },
  
  {
    key: "configuration",
    name: "Configurations",
    icon: <AdminPanelSettingsIcon />,
    child: [
      // {
      //   key: "profiles",
      //   name: "Profiles",
      //   link: "/app/configurations/profiles"
      // },
      {
        key: "users",
        name: "Users",
        link: "/app/configurations/users"
      },
      {
        key: "roles",
        name: "Roles",
        link: "/app/configurations/roles"
      },
      // {
      //   key: "financier",
      //   name: "Financier",
      //   link: "/app/configurations/financier"
      // }
    ]
  },
  // {
  //   key: "setting",
  //   name: "Settings",
  //   icon: <SettingsSuggestIcon />,
  //   child: [
  //     {
  //       key: "events",
  //       name: "Event logs",
  //       link: "/app/settings/event-logs"
  //     },
  //     {
  //       key: "notifications",
  //       name: "Notification Templates",
  //       link: "/app/settings/notification-templates"
  //     }
  //   ]
  // }
];
export default dataMenu;
