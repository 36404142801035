/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from "redux";
import login from "./modules/login";
import uiReducer from "./modules/ui";
import initval from "./modules/initForm";
import { app } from "./modules/appReducer";
import { permissions } from "./modules/permissions";
import { loader } from "./modules/loading";

const appReducer = combineReducers({
  login,
  ui: uiReducer,
  initval,
  app,
  permissions,
  loader
});

const rootReducerFun = (state, action) => {
  return appReducer(state, action);
};

export default rootReducerFun;
