import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function AppLoader(props) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 1301 }} open={props.loader}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default AppLoader;
