import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Link, Route } from "react-router-dom";
import useStyles from "./breadCrumb-jss";

const Breadcrumbs = (props) => {
  const { classes, cx } = useStyles();
  const { theme, separator, location } = props;
  const url = location.pathname;
  const segments = url.split("/").filter((segment) => segment !== ""); // Split URL by '/' and remove empty segments
  const breadcrumbItems = [];

  let accumulatedPath = "";
  const generateDisplayName = (segment) => {
    if (segment.includes("-")) {
      return segment
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else {
      if (/[A-Z]/.test(segment)) {
        return segment.replace(/([A-Z])/g, " $1");
      } else {
        return segment.charAt(0).toUpperCase() + segment.slice(1);
      }
    }
  };

  for (let i = 0; i < segments.length; i++) {
    accumulatedPath += `/${segments[i]}`;
    breadcrumbItems.push(
      <span key={i}>
        <Link underline="hover" to={accumulatedPath}>
          {generateDisplayName(segments[i])}
        </Link>
        {i < segments.length - 1 && separator}
      </span>
    );
  }

  return (
    <section
      className={cx(
        theme === "dark" ? classes.dark : classes.light,
        classes.breadcrumbs
      )}
    >
      <p>
        You are here:
        {breadcrumbItems}
      </p>
    </section>
  );
};

Breadcrumbs.propTypes = {
  location: PropTypes.object,
  theme: PropTypes.string,
  separator: PropTypes.string
};

export default Breadcrumbs;
