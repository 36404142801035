import { makeStyles } from "tss-react/mui";
import { alpha } from "@mui/material/styles";
import { gradientBgLight } from "../../containers/Templates/appStyles-jss";
import { cyan, indigo, red } from "@mui/material/colors";

const user = localStorage.getItem("token");

const rootWraper = {
  display: "flex",
  width: "70%",
  zIndex: 1,
  position: "relative",
};

const wrapper = (theme, opacity) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  // backgroundColor: alpha(theme.palette.background.paper, opacity),
  // backgroundColor: theme.palette.secondary.main,
  backgroundColor: "#007ad4",
  borderRadius: theme.spacing(3),
  backgroundRepeat: "no-repeat",
  color: theme.palette.text.primary,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
});

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    ...rootWraper,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mainColor: {
    color: "#007ad4",
  },
  chooseFile: {
    color: "white",
    backgroundColor: "#007ad4",
    "&:hover": {
      backgroundColor: "#007ad4",
      borderColor: "#007ad4",
    },
  },
  rootFull: {
    ...rootWraper,
    height: "100%",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      overflow: "hidden",
    },
  },
  containerSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      overflow: "hidden",
    },
  },
  paperWrap: {
    ...wrapper(theme, 1),
  },
  sideWrap: {
    ...wrapper(theme, 1),
    height: "100%",
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      width: 480,
    },
    [`& .${classes.topBar}`]: {
      marginBottom: theme.spacing(4),
    },
  },
  fullWrap: {
    ...wrapper(theme, 0.9),
    height: "100%",
    borderRadius: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [`& .${classes.topBar}`]: {
      width: "100%",
    },
  },
  petal: {
    backgroundImage:
      theme.palette.mode === "dark"
        ? `url(${"/images/petal_bg.svg"})`
        : `url(${"/images/petal_grey_bg.svg"})`,
  },
  icon: {},
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    [`& .${classes.icon}`]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginBottom: theme.spacing(3),
      "& a": {
        display: "none",
      },
    },
  },
  outer: {},
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    position: "relative",
    fontSize: 16,
    fontWeight: 500,
    // color: theme.palette.text.primary,
    color: "white",

    textDecoration: "none",
    [`&.${classes.outer}`]: {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(1),
    },
    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  chooseFile: {
    color: "#2f569d",
    "&:hover": {
      backgroundColor: "#007ad4",
      borderColor: "#007ad4",
    },
  },
  formWrap: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 2rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 2.5rem",
    },
  },
  form: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 1.2em",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 2em",
    },
  },
  pageFormWrap: {
    width: "100%",
    margin: `${theme.spacing(2)} auto`,
    [theme.breakpoints.up("sm")]: {
      width: 480,
    },
  },
  pageFormSideWrap: {
    margin: "0 auto",
    [theme.breakpoints.only("sm")]: {
      width: 480,
    },
  },
  formControl: {
    width: "90%",
    marginBottom: theme.spacing(1),
  },
  socmedLogin: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 2.5rem",
    },
    "& button": {
      padding: "4px 24px",
    },
  },
  socmedSideLogin: {
    padding: "24px 24px 1px",
    margin: "0 auto",
    "& button": {
      padding: "4px 16px",
      margin: `0 ${theme.spacing(1)}`,
    },
    [theme.breakpoints.only("sm")]: {
      width: 480,
    },
  },
  userFormWrap: {
    width: "90%",
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      width: "95%",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
      marginTop: user ? theme.spacing(3) : 0,
    },
  },
  sideFormWrap: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fullFormWrap: {
    height: "100%",
    width: "100%",
  },
  title: {
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontSize: 14,
  },
  titleGradient: {
    background: gradientBgLight(theme),
    fontWeight: "bold",
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(3),
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.55em",
    },
  },
  opening: {
    color: theme.palette.common.white,
    width: "100%",
    textAlign: "center",
    "& h1": {
      display: "block",
      [theme.breakpoints.down("lg")]: {
        fontSize: 32,
        lineHeight: "48px",
      },
    },
    "& p": {
      color: theme.palette.common.white,
      fontSize: 18,
      [theme.breakpoints.down("lg")]: {
        fontSize: 14,
      },
    },
  },
  label: {},
  btnArea: {
    display: "flex",
    justifyContent: "space-around",
    margin: `${theme.spacing(2)} 0`,
    fontSize: 12,
    [`& .${classes.label}`]: {
      fontSize: 12,
      "& span": {
        fontSize: 12,
      },
    },
    "& button": {
      margin: `0 ${theme.spacing(1)}`,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      "& button": {
        width: "70%",
        margin: 5,
      },
    },
  },
  btnAreaModal: {
    display: "flex",
    justifyContent: "end",
    gap: "8px",
    marginTop: "1em",
  },
  noMargin: {
    margin: 0,
  },
  redBtn: {
    color: red[500],
    borderColor: red[500],
    "&:hover": {
      borderColor: red[700],
    },
  },
  blueBtn: {
    color: indigo[300],
    borderColor: indigo[300],
    "&:hover": {
      borderColor: indigo[500],
    },
  },
  cyanBtn: {
    color: cyan[500],
    borderColor: cyan[500],
    "&:hover": {
      borderColor: cyan[700],
    },
  },
  submitBtn: {
    backgroundColor: "#ffbd59",
    color: "#007ad4",
    borderRadius: "1em",
    "&:hover": {
      backgroundColor: "#e6a747",
    },
  },
  modalBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderRadius: "1em",
    padding: "4px 12px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonLink: {
    background: "none",
    padding: 0,
    textTransform: "none",
    transition: "color ease 0.3s",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "0.875rem",
    "&:hover": {
      textDecoration: "underline",
      transform: `scale(1.1)`,
      backgroundColor: "transparent",
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  footer: {
    textAlign: "center",
    padding: 5,
    background: theme.palette.grey[100],
    fontSize: 14,
    position: "relative",
  },
  welcomeWrap: {
    position: "relative",
  },
  tab: {
    margin: `${theme.spacing(3)} 0 ${theme.spacing(1)}`,
  },
  link: {
    fontSize: "0.875rem",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  socmedFull: {
    textAlign: "center",
    width: "100%",
    margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
    "& button": {
      width: "100%",
      display: "block",
      margin: `0 auto ${theme.spacing(2)}`,
    },
    [theme.breakpoints.up("sm")]: {
      "& button": {
        width: 400,
      },
    },
  },
  lockWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  lockForm: {
    display: "flex",
    alignItems: "baseline",
  },
  unlockBtn: {
    top: -4,
  },
  notifyForm: {
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      "& button": {
        marginTop: theme.spacing(3),
        width: "100%",
      },
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      "& button": {
        width: "auto",
      },
    },
  },
  lockField: {
    marginRight: theme.spacing(1),
    "& label": {
      color: `${theme.palette.common.white} !important`,
    },
    "& label + div": {
      background: alpha(theme.palette.primary.light, 0.3),
      border: "none",
      "& svg": {
        fill: alpha(theme.palette.common.white, 0.7),
      },
    },
  },
  avatar: {
    width: 150,
    height: 150,
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(3),
    },
    boxShadow: theme.glow.medium,
  },
  userName: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      textAlign: "center",
    },
  },
  hint: {
    padding: theme.spacing(1),
  },
  brandCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  centerAdornment: {
    justifyContent: "center",
    "& > div": {
      width: "100%",
    },
    "& aside": {
      top: -10,
      [theme.breakpoints.up("sm")]: {
        left: 10,
      },
      position: "relative",
    },
    "& a i": {
      width: 32,
      height: 32,
    },
  },
  centerV: {
    justifyContent: "center",
  },
  optArea: {
    display: "flex",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(0.5)}`,
  },
  userFormGrid: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
