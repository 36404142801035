import qs from "qs";
import axios from "axios";
import Swal from "sweetalert2";

function checkHTTPStatus(status) {
  if (status === 401) {
    localStorage.setItem("sessionExpired", true);
  } else if (status === 404) {
    return false;
  }
}

const Timeout = (time) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), time * 5000);
  return controller;
};

export const post = async ({ url, success, failure, dispatch, body }) => {
  // console.log("log post method starts", new Date());
  let data;
  const bodyValue = body;
  try {
    let mainBody = "";
    if (bodyValue === "") {
      mainBody = "";
    } else {
      mainBody = JSON.stringify(bodyValue);
    }
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    const res = await fetch(url, {
      signal: Timeout(30).signal,
      method: "POST",
      headers: customHeaders,
      body: mainBody,
      withCredentials: true,
    });
    data = await res.json();
    // console.log("log post method 1 ", new Date());

    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    // console.log("log post method 2", new Date());
    let error;
    if (e.name == "AbortError") {
      error = "Connection request timeout. Please refresh the page!";
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
        allowOutsideClick: false,
        confirmButtonColor: "#093f7a",
      });
    } else {
      error = data;
    }
    if (failure) {
      dispatch({ type: failure, error });
    }
  }
};
// export const post = async (url, body, auth) => {
//   let data;
//   try {
//     const customHeaders = {
//       "Content-Type": "application/json",
//     };
//     const auth = localStorage.getItem("token");
//     customHeaders.authorization = auth;

//     const res = await fetch(url, {
//       method: "POST",
//       headers: customHeaders,
//       body: JSON.stringify(body),
//     });
//     data = await res.json();
//     checkHTTPStatus(res.status);
//     if (data.status) {
//       return data;
//     }
//     if (!data.status) {
//       let error = new Error(data.message);
//       error = data;
//       throw error;
//     }
//   } catch (e) {
//     if (e) {
//       throw e;
//     }
//   }
// };

export const get = async ({ url, success, failure, dispatch, body }) => {
  let data;
  let bodyValue = body;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    if (body === "default" || !body) {
      bodyValue = "";
    }
    let finalurl = "";
    if (bodyValue === "") {
      finalurl = `${url}`;
    } else {
      finalurl = `${url}?${qs.stringify(bodyValue)}`;
    }
    const res = await fetch(finalurl, {
      signal: Timeout(30).signal,
      method: "get",
      headers: customHeaders,
      withCredentials: true,
    });
    data = await res.json();
    data.initialCall = true;
    checkHTTPStatus(res.status);
    if (res.status == 404) {
      dispatch({ type: failure, data: false });
    } else {
      dispatch({ type: success, data });
    }
  } catch (e) {
    let error;
    if (e.name == "AbortError") {
      error = "Connection request timeout. Please refresh the page!";
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
        allowOutsideClick: false,
        confirmButtonColor: "#093f7a",
      });
    } else {
      error = data;
    }
    if (failure) {
      dispatch({ type: failure, error });
    }
  }
};

export const put = async ({ url, success, failure, dispatch, body }) => {
  let data;
  const bodyValue = body;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    const res = await fetch(url, {
      method: "PUT",
      headers: customHeaders,
      body: JSON.stringify(bodyValue),
      withCredentials: true,
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};

export const del = async ({ url, success, failure, dispatch, body }) => {
  let data;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    const res = await fetch(url, {
      method: "DELETE",
      headers: customHeaders,
      body: JSON.stringify(body),
      withCredentials: true,
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};
