import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import AllInclusive from "@mui/icons-material/AllInclusive";
import Brightness5 from "@mui/icons-material/Brightness5";
import People from "@mui/icons-material/People";
import useMediaQuery from "@mui/material/useMediaQuery";
import brand from "../../assets/dummy/brand";
import { TextFieldRedux, CheckboxRedux } from "./ReduxFormMUI";
import useStyles from "./user-jss";
import { ContentDivider } from "../Divider";
import { Box, FormLabel } from "@mui/material";

// validation functions
const required = (value) => (value === null ? "Required" : undefined);
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email"
    : undefined;

const passwordsMatch = (value, allValues) => {
  if (value !== allValues.password) {
    return "Passwords dont match";
  }
  return undefined;
};

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function RegisterForm(props) {
  const { classes, cx } = useStyles();
  const [tab, setTab] = useState(0);

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleChangeTab = (event, value) => {
    setTab(value);
  };

  const { handleSubmit, pristine, submitting, deco } = props;
  return (
    <Fragment>
      <Paper className={cx(classes.paperWrap, deco && classes.petal)}>
        {!mdDown && (
          <div className={classes.topBar}>
            <NavLink to="/" className={classes.brand}>
              <img src={"/images/logo.svg"} alt={brand.name} />
              {brand.name}
            </NavLink>
            <Button
              size="small"
              className={classes.buttonLink}
              component={LinkBtn}
              to="/login"
            >
              Already have account ?
            </Button>
          </div>
        )}
        {!mdUp && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <NavLink to="/" className={cx(classes.brand, classes.outer)}>
              <img src={"/images/logo.svg"} alt={brand.name} />
              {brand.name}
            </NavLink>
            <Button
              size="small"
              className={classes.buttonLink}
              component={LinkBtn}
              to="/login"
            >
              Login
            </Button>
          </Box>
        )}
        {/* <Typography variant="h4" className={classes.title} gutterBottom>
          Register
        </Typography> */}
        <section className={classes.socmedLogin}>
          <ContentDivider content="Get onboarded with Accept My Invoice" />
        </section>
        <section className={classes.formWrap}>
          <form onSubmit={handleSubmit}>
            <div>
              <FormControl variant="standard" className={classes.formControl}>
                <FormLabel>
                  Email<span>*</span>
                </FormLabel>
                <Field
                  name="email"
                  component={TextFieldRedux}
                  placeholder="Email"
                  required
                  validate={[required, email]}
                  className={classes.field}
                />
              </FormControl>
            </div>
            <div>
              <FormControl variant="standard" className={classes.formControl}>
                <FormLabel>
                  Password<span>*</span>
                </FormLabel>
                <Field
                  name="password"
                  component={TextFieldRedux}
                  type="password"
                  required
                  validate={[required, passwordsMatch]}
                  className={classes.field}
                />
              </FormControl>
            </div>
            <div>
              <FormControl variant="standard" className={classes.formControl}>
                <FormLabel>
                  Confirm Password<span>*</span>
                </FormLabel>
                <Field
                  name="passwordConfirm"
                  component={TextFieldRedux}
                  type="password"
                  required
                  validate={[required, passwordsMatch]}
                  className={classes.field}
                />
              </FormControl>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Field
                    name="checkbox"
                    component={CheckboxRedux}
                    required
                    className={classes.agree}
                  />
                }
                label="Agree with Terms &amp; Conditions"
              />
            </div>
            <div className={classes.btnArea}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Sign up
              </Button>
            </div>
          </form>
        </section>
      </Paper>
    </Fragment>
  );
}

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  deco: PropTypes.bool
};

const RegisterFormReduxed = reduxForm({
  form: "registerForm",
  enableReinitialize: true
})(RegisterForm);

const RegisterFormMapped = connect((state) => ({
  deco: state.rootReducer.ui.decoration
}))(RegisterFormReduxed);

export default RegisterFormMapped;
