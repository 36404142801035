import * as types from "../actions/actionTypes";
import { initialState } from "./initialState";

export const permissions = (state = initialState.permissions, action) => {
  switch (action.type) {
    // all permissions
    case types.ALL_PERMISSIONS:
      return {
        ...state,
        allPermissions: false,
        loading: true,
        error: false,
      };
    case types.ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allPermissions: action.data,
        loading: false,
        error: false,
      };
    case types.ALL_PERMISSIONS_ERROR:
      return { ...state, allPermissions: false, loading: false, error: true };

    // role permission
    case types.USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: false,
        loading: true,
        error: false,
      };
    case types.USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        userPermissions: action.data,
        loading: false,
        error: false,
      };
    case types.USER_PERMISSIONS_ERROR:
      return { ...state, userPermissions: false, loading: false, error: true };

    // login api permission
    case types.LOGIN:
      return {
        ...state,
        loginData: false,
        loading: true,
        error: false,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loginData: action.data,
        loading: false,
        error: false,
      };
    case types.PROFILE_DATA_ERROR:
      return { ...state, loginData: false, loading: false, error: true };

    // PROFILE DATA
    case types.PROFILE_DATA:
      return {
        ...state,
        profileData: false,
        loading: true,
        error: false,
      };
    case types.PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profileData: action.data,
        loading: false,
        error: false,
      };
    case types.PROFILE_DATA_ERROR:
      return { ...state, profileData: false, loading: false, error: true };

    // ENTITIES DATA
    case types.ENTITIES_LIST:
      return {
        ...state,
        entities: false,
        loading: true,
        error: false,
      };
    case types.ENTITIES_LIST_SUCCESS:
      return {
        ...state,
        entities: action.data,
        loading: false,
        error: false,
      };
    case types.ENTITIES_LIST_ERROR:
      return { ...state, entities: false, loading: false, error: true };

    // default
    default:
      return state;
  }
  return state;
};
