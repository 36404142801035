import React, { Fragment, useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import { HeaderMenu, BreadCrumb } from "../../../components";
import dataMenu from "../../../assets/ui/menu";
import Decoration from "../Decoration";
import useStyles from "../appStyles-jss";
import { useLocation, useOutlet } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import AppLoader from "src/components/Loading/appLoader";

function DropMenuLayout(props) {
  const { classes, cx } = useStyles();
  const location = useLocation();
  const outlet = useOutlet();
  const [fixed, setFixed] = useState(false);
  // Initial menu ui
  let flagFixedMenu = false;

  const handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagFixedMenu = scroll > 64;
    if (flagFixedMenu !== newFlagFixedMenu) {
      setFixed(newFlagFixedMenu);
      flagFixedMenu = newFlagFixedMenu;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    children,
    pageLoaded,
    mode,
    gradient,
    deco,
    bgPosition,
    changeMode,
    place,
    history,
    titleException,
    handleOpenGuide,
    toggleDrawer,
    sidebarOpen,
    loadTransition
  } = props;
  return (
    <Fragment>
      <HeaderMenu
        type="top-navigation"
        dataMenu={dataMenu}
        changeMode={changeMode}
        mode={mode}
        history={history}
        openGuide={handleOpenGuide}
        toggleDrawerOpen={toggleDrawer}
        openMobileNav={sidebarOpen}
        loadTransition={loadTransition}
        logoLink="/login"
        fixed={fixed}
      />
      <main className={cx(classes.content, classes.mainfixed)} id="mainContent">
        <Decoration
          mode={mode}
          gradient={gradient}
          decoration={deco}
          bgPosition={bgPosition}
          horizontalMenu
        />
        <section className={cx(classes.mainWrap, classes.topbarLayout)}>
          {titleException.indexOf(location.pathname) < 0 && (
            <div className={classes.pageTitle}>
              <Typography
                component="h4"
                className={
                  bgPosition === "header"
                    ? classes.darkTitle
                    : classes.lightTitle
                }
                variant="h4"
              >
                {place}
              </Typography>
              <BreadCrumb
                separator=" / "
                theme={bgPosition === "header" ? "dark" : "light"}
                location={location}
              />
            </div>
          )}
          {!pageLoaded && (
            <img
              src="/images/spinner.gif"
              alt="spinner"
              className={classes.circularProgress}
            />
          )}
          <Fade in={pageLoaded} {...(pageLoaded ? { timeout: 700 } : {})}>
            <div className={!pageLoaded ? classes.hideApp : ""}>
              {/* Application content will load here */}
              <AppLoader loader={props.loading} />
              {children}
            </div>
          </Fade>
        </section>
      </main>
    </Fragment>
  );
}

DropMenuLayout.propTypes = {
  children: PropTypes.node,
  changeMode: PropTypes.func,
  toggleDrawer: PropTypes.func,
  loadTransition: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  pageLoaded: PropTypes.bool,
  mode: PropTypes.string,
  gradient: PropTypes.bool,
  deco: PropTypes.bool,
  bgPosition: PropTypes.string,
  place: PropTypes.string,
  titleException: PropTypes.array,
  handleOpenGuide: PropTypes.func
};

const mapStateToProps = (app) => ({
  loading:
    app.rootReducer.app.loading ||
    app.rootReducer.permissions.loading ||
    app.rootReducer.loader.loader
});

export default connect(mapStateToProps, {})(DropMenuLayout);
