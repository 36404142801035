import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import {
  Login,
  Register,
  ResetPassword,
  NotFound,
  SessionOut,
  DashboardPage,
  Error,
  Users,
  Clients,
  Financier,
  UserForm,
  RolesTable,
  SellerTable,
  SellerPage,
  RoleForm,
  InvoicePage,
  BuyerTable,
  BuyerPage,
  SellerBuyerForm,
  InvoiceAcceptancePage,
  SendInvoice,
  BuyerInvoice,
  SellerInvoice,
  ResetPasswordSend,
  InvoiceDetails,
  LogsDetails,
  InvoiceLogs,
  InvoiceList,
  InvoiceVerificationPage,
  EWayDetails,
  VerifyInvoice,
  MisReport,
  CapturePhoto,
  POD,
  PODpage,
  AddGSTINForm,
} from "../pageListAsync";
import PropTypes from "prop-types";
import Auth from "./Auth";
import { Loading } from "src/redux/actions/uiActions";
import { connect } from "react-redux";
import { AuthLayout } from "./Layout";
import DashboardMaped from "../Templates/Dashboard";
import Configuration from "../Templates/Configuration";
import history from "src/utils/history";

// function Admin(props) {
//   console.log("routesPorps", props);
//   debugger;
//   React.useEffect(() => {
//     if (localStorage.getItem("sessionExpired") === "true") {
//       debugger;
//       // localStorage.clear();
//       history.push("/sessionOut");
//     }
//   }, [props]);
//   return;
// }

// Admin.propTypes = {
//   Loading: PropTypes.func,
// };

// const mapStateToProps = (app) => ({
//   data: app.rootReducer,
// });

// export default connect(mapStateToProps, {
//   Loading,
// })(Admin);

const getUserData = () => {
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("token");
      resolve(user);
    }, 3000)
  );
};
export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >
        {/* Auth Routes : when user is not logged in */}
        <Route element={<Auth />}>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        {/* App Routes : when user is logged in */}
        <Route path="/app" element={<DashboardMaped history={history} />}>
          <Route index element={<DashboardPage />} />
          <Route path="service-pod" element={<POD />} />
          <Route path="my-sellers" element={<SellerTable />} />
          <Route path="my-sellers/seller" element={<SellerPage />} />
          <Route path="my-sellers/seller/invoice" element={<InvoicePage />} />
          <Route path="my-sellers/add" element={<SellerBuyerForm />} />
          <Route path="my-gstin/add" element={<AddGSTINForm />} />
          <Route path="my-sellers/edit" element={<SellerBuyerForm />} />
          <Route path="my-buyers" element={<BuyerTable />} />
          <Route path="my-buyers/buyer" element={<BuyerPage />} />
          <Route path="my-buyers/buyer/invoice" element={<InvoicePage />} />
          <Route path="my-buyers/add" element={<SellerBuyerForm />} />
          <Route path="my-buyers/edit" element={<SellerBuyerForm />} />
          <Route path="configurations" element={<Configuration />}>
            <Route path="users" element={<Users />} />
            <Route path="users/add" element={<UserForm />} />
            <Route path="users/edit" element={<UserForm />} />
            <Route path="profiles" element={<Clients />} />
            <Route path="financier" element={<Financier />} />
            <Route path="roles" element={<RolesTable />} />
            <Route path="roles/add" element={<RoleForm />} />
            <Route path="roles/edit" element={<RoleForm />} />
            <Route path="roles/clone" element={<RoleForm />} />
          </Route>
          <Route path="user/profile" element={<UserForm />} />
          <Route path="invoices" element={<InvoicePage />} />
          <Route path="invoices/details" element={<InvoiceDetails />} />
          <Route path="logs/details" element={<LogsDetails />} />
          <Route path="e-way-details" element={<EWayDetails />} />
          <Route path="invoices/logs" element={<InvoiceLogs />} />
          <Route path="invoices/table" element={<InvoiceList />} />
          <Route
            path="invoice-acceptance"
            element={<InvoiceAcceptancePage />}
          />
          <Route path="mis" element={<MisReport />} />
          <Route
            path="invoice-verification"
            element={<InvoiceVerificationPage />}
          />
        </Route>
        {/* global routes */}
        <Route path="404" element={<NotFound />} />
        <Route path="sessionOut" element={<SessionOut />} />
        <Route path="invoices/acceptance" element={<SendInvoice />} />
        <Route path="user/password" element={<ResetPasswordSend />} />
        <Route path="invoices/verify" element={<VerifyInvoice />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/pod/upload" element={<CapturePhoto />} />
      </Route>
    </>
  )
);
