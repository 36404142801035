import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import brand from "../../assets/dummy/brand";
import dummy from "../../assets/dummy/dummyContents";
import MainMenu from "./MainMenu";
import useStyles from "./sidebar-jss";
import { connect } from "react-redux";

function SidebarContent(props) {
  
  const { classes, cx } = useStyles();
  const [transform, setTransform] = useState(0);

  const handleScroll = (event) => {
    const scroll = event.target.scrollTop;
    setTransform(scroll);
  };

  useEffect(() => {
    const mainContent = document.getElementById("sidebar");
    mainContent.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const imageURL = `${process.env.REACT_APP_AWS_PATH}${process.env.REACT_APP_AWS_PROFILE_PICTURES}${props?.profile?.data?.ProfilePic}`;

  const {
    turnDarker,
    drawerPaper,
    toggleDrawerOpen,
    loadTransition,
    leftSidebar,
    dataMenu,
    isLogin
  } = props;

  return (
    <div
      className={cx(
        classes.drawerInner,
        !drawerPaper ? classes.drawerPaperClose : ""
      )}
    >
      <div className={classes.drawerHeader}>
        <NavLink
          to="/login"
          className={cx(
            classes.brand,
            classes.brandBar,
            turnDarker && classes.darker
          )}
        >
          <img src={"/images/logo.svg"} alt={brand.name} />
          {brand.name}
        </NavLink>
        {isLogin && (
          <div
            className={cx(classes.profile, classes.user)}
            style={{
              opacity: 1 - transform / 100,
              marginTop: transform * -0.3
            }}
          >
            <Avatar
              alt={props.profile.data?.FirstName}
              src={imageURL || dummy.user.avatar}
              className={cx(classes.avatar, classes.bigAvatar)}
            />
            <div>
              <h4>
                {props.profile.data?.FirstName} {props.profile.data?.LastName}
              </h4>
            </div>
          </div>
        )}
      </div>
      <div
        id="sidebar"
        className={cx(
          classes.menuContainer,
          leftSidebar && classes.rounded,
          isLogin && classes.withProfile
        )}
      >
        <MainMenu
          loadTransition={loadTransition}
          dataMenu={dataMenu}
          toggleDrawerOpen={toggleDrawerOpen}
        />
      </div>
    </div>
  );
}

SidebarContent.propTypes = {
  drawerPaper: PropTypes.bool,
  turnDarker: PropTypes.bool,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  leftSidebar: PropTypes.bool,
  dataMenu: PropTypes.array,
  isLogin: PropTypes.bool
};

SidebarContent.defaultProps = {
  turnDarker: false,
  toggleDrawerOpen: () => {},
  loadTransition: () => {},
  anchorEl: null,
  isLogin: true
};

const mapStateToProps = (state) => ({
  profile: state.rootReducer.permissions.profileData
});

export default connect(mapStateToProps)(SidebarContent);
