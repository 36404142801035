import React, { useState, useEffect, Fragment, useContext } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { BreadCrumb, Sidebar, Header } from "../../components";
import dataMenu from "../../assets/ui/menu";
import {
  toggleAction,
  openAction,
  playTransitionAction
} from "../../redux/actions/uiActions";
import useStyles from "./appStyles-jss";
import {
  Navigate,
  useLocation,
  useNavigate,
  useOutlet
} from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { ThemeContext } from "../App/ThemeWrapper";
import DropMenuLayout from "./layouts/DropMenuLayout";

function Dashboard(props) {
  const { classes, cx } = useStyles();
  const location = useLocation();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { user } = useAuth();
  const changeMode = useContext(ThemeContext);

  function handleMenuNavigation(route) {
    navigate(route);
  }

  // Initial header style
  const [openGuide, setOpenGuide] = useState(false);
  const [appHeight, setAppHeight] = useState(0);

  useEffect(() => {
    const { history, loadTransition } = props;

    // Adjust min height
    setAppHeight(window.innerHeight + 112);

    // Set expanded sidebar menu
    const currentPath = location.pathname;
    props.initialOpen(currentPath);
    // Play page transition
    loadTransition(true);

    // Execute all arguments when page changes
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        loadTransition(true);
      }, 500);
    });

    return () => {
      if (unlisten != null) {
        unlisten();
      }
    };
  }, []);

  const handleOpenGuide = () => {
    setOpenGuide(true);
  };
  const handleCloseGuide = () => {
    setOpenGuide(false);
  };

  const {
    children,
    toggleDrawer,
    sidebarOpen,
    loadTransition,
    pageLoaded,
    mode,
    history,
    gradient,
    deco,
    bgPosition,
    layout
  } = props;
  const titleException = [
    "/login",
    "/app/crm-dashboard",
    "/app/crypto-dashboard"
  ];
  const sessionExpired = localStorage.getItem("sessionExpired");
  const parts = location.pathname.split("/");
  const place = parts[parts.length - 1].replace("-", " ");
  if (sessionExpired) {
    localStorage.clear();
    return <Navigate to="/sessionOut" />;
  }
  return (
    <div
      // style={{ minHeight: appHeight }}
      className={cx(
        classes.appFrameInner,
        layout === "top-navigation" || layout === "mega-menu"
          ? classes.topNav
          : classes.sideNav,
        mode === "dark" ? "dark-mode" : "light-mode"
      )}
    >
      {
        /* Top Bar with Dropdown Menu */
        layout === "top-navigation" && (
          <DropMenuLayout
            history={history}
            toggleDrawer={toggleDrawer}
            loadTransition={loadTransition}
            changeMode={changeMode}
            sidebarOpen={sidebarOpen}
            pageLoaded={pageLoaded}
            mode={mode}
            gradient={gradient}
            deco={deco}
            bgPosition={bgPosition}
            place={place}
            titleException={titleException}
            handleOpenGuide={handleOpenGuide}
          >
            {outlet}
          </DropMenuLayout>
        )
      }
    </div>
  );
}

Dashboard.propTypes = {
  // children: PropTypes.node,
  initialOpen: PropTypes.func,
  toggleDrawer: PropTypes.func,
  loadTransition: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  pageLoaded: PropTypes.bool,
  mode: PropTypes.string,
  gradient: PropTypes.bool,
  deco: PropTypes.bool,
  bgPosition: PropTypes.string,
  layout: PropTypes.string
};

const mapStateToProps = (state) => ({
  sidebarOpen: state.rootReducer.ui.sidebarOpen,
  pageLoaded: state.rootReducer.ui.pageLoaded,
  mode: state.rootReducer.ui.type,
  gradient: state.rootReducer.ui.gradient,
  deco: state.rootReducer.ui.decoration,
  layout: state.rootReducer.ui.layout,
  bgPosition: state.rootReducer.ui.bgPosition
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawer: () => dispatch(toggleAction),
  initialOpen: bindActionCreators(openAction, dispatch),
  loadTransition: bindActionCreators(playTransitionAction, dispatch)
});

const DashboardMaped = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardMaped;
