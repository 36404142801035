import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";

/* Textfield */
export const TextFieldRedux = (props) => {
  return <TextField variant="standard" label={props.label} />;
};

TextFieldRedux.propTypes = {
  props: PropTypes.object
};

/* End */

/* Select */
export const SelectRedux = ({ children, ...props }) => (
  <Select variant="standard" {...props}>
    {children}
  </Select>
);

SelectRedux.propTypes = {
  children: PropTypes.node
};
/* End */

/* Checkbox */
export const CheckboxRedux = ({ ...props }) => (
  <Checkbox checked={props.value} {...props} />
);

CheckboxRedux.propTypes = {
  props: PropTypes.object
};
/* End */

/* Switch */
export const SwitchRedux = ({ ...props }) => (
  <Switch checked={props.value} {...props} />
);

SwitchRedux.propTypes = {
  props: PropTypes.object
};
/* End */
