import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { openMenuAction, closeMenuAction } from "../../redux/actions/uiActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import useStyles from "./header-jss";
import UserMenu from "./UserMenu";
import brand from "../../assets/dummy/brand";
import { Loading } from "src/redux/actions/uiActions";
import { useLocation } from 'react-router-dom';

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

// eslint-disable-next-line
function MainMenu(props) {
  const location = useLocation();
  const { classes, cx } = useStyles();
  let ref = useRef();
  const { open, dataMenu, closeDrawer,Loading } = props;
  const [active, setActive] = useState([]);
  const [isParentActive, setIsParentActive] = useState(false);
  const [isChildActive, setIsChildActive] = useState(false);
  const [openMenu, setOpenMenu] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    const { open } = props;
    setTimeout(() => {
      setActive(open);
    }, 50);
  }, []);

  const handleOpenMenu = (event, key, keyParent) => {
    const { openSubMenu } = props;
    setIsParentActive(true);
    openSubMenu(key, keyParent);
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setOpenMenu([key]);
    }, 50);
  };

  const handleButtonLeave = (event) => {
    if (!isChildActive) {
      setIsParentActive(false);
      handleClose(event);
    }
  };

  const handleClose = (event) => {
    if (anchorEl?.contains(event.target)) {
      return;
    }
    setOpenMenu([]);
  };

  const handleActiveParent = (key) => {
    Loading(true)
    setOpenMenu([]);
    setActive([key]);
    setTimeout(()=>{
      Loading(false);
    },3500)
  };

  const handleParentEnter = (key) => {
    setOpenMenu([key]);
  };

  const getMenus = (parent, menuArray) =>
    menuArray?.map((item, index) => {
      const isSelected = location.pathname.includes(item.key);
      if (item.multilevel) {
        return false;
      }
      if (item.linkParent && item.Permission) {
        return (
          <Button
            style={{padding:"4px 8px", margin:"0px 6px"}}
            key={`${item.key}-${index.toString()}`}
            aria-owns={open ? "menu-list-grow" : undefined}
            component={LinkBtn}
            className={cx(
              classes.headMenu,
              open.indexOf(item.key) > -1 ? classes.opened : "",
              isSelected ? classes.selected : ""
            )}
            onMouseEnter={() => handleParentEnter(item.key)}
            onClick={() => handleActiveParent(item.key)}
            to={item.linkParent}
          >
            {" "}
            {item.name}
          </Button>
        );
      }
      if (item.child && item.Permission) {
        return (
          <div key={index.toString()}>
            <Button
              style={{padding:"4px 8px",margin:"0px 6px"}}
              aria-owns={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              className={cx(
                classes.headMenu,
                open.indexOf(item.key) > -1 ? classes.opened : "",
                isSelected ? classes.selected : ""
              )}
              onMouseEnter={(event) =>
                handleOpenMenu(event, item.key, item.keyParent)
              }
              onMouseLeave={(event) => {
                handleButtonLeave(event);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              ref={ref}
            >
              {item.name}
            </Button>
            {item.child && (
              <Popper
                open={
                  (isParentActive || isChildActive) &&
                  openMenu.indexOf(item.key) > -1
                }
                anchorEl={anchorEl}
                transition
                disablePortal
                placement="bottom-start"
                onMouseLeave={(event) => {
                  handleClose(event);
                  setIsChildActive(false);
                }}
                onMouseEnter={() => setIsChildActive(true)}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper className={classes.dropDownMenu}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <List
                          role="menu"
                          component="nav"
                          className={classes.paperMenu}
                        >
                          {getMenus(item.key, item.child)}
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )}
          </div>
        );
      }
      if (item.link) {
        return (
          <ListItem
            key={`${item.key}-${index.toString()}`}
            className={classes.menuItem}
            activeClassName={classes.active}
            component={LinkBtn}
            to={item.link}
            onClick={() => handleActiveParent(parent)}
          >
            <ListItemText primary={item.name} className={classes.linkColor} />
          </ListItem>
        );
      }
    });
  return (
    <nav className={classes.mainMenu}>
      {/* <NavLink
        to={"/app"}
        className={classes.brand}
        onClick={() => {
          setOpenMenu([]);
          setIsParentActive(false);
          setActive([]);
        }}
      >
        <img src={"/images/logo.svg"} alt={brand.name} />
      </NavLink> */}
      <div style={{ width: "100%" }}>{getMenus(null, dataMenu)}</div>
      <Toolbar>
        <UserMenu dark />
      </Toolbar>
    </nav>
  );
}

MainMenu.propTypes = {
  open: PropTypes.array,
  openSubMenu: PropTypes.func,
  dataMenu: PropTypes.array,
};

const openAction = (key, keyParent) => ({
  type: "OPEN_SUBMENU",
  key,
  keyParent,
});

const mapStateToProps = (state) => ({
  open: state.rootReducer.ui.subMenuOpen,
  closeDrawer: PropTypes.func,
});

const mapDispatchToProps = (dispatch) => ({
  openSubMenu: bindActionCreators(openAction, dispatch),
  closeDrawer: () => dispatch(closeMenuAction),
});

const MainMenuMapped = connect(mapStateToProps, mapDispatchToProps)(MainMenu);

export default connect(mapStateToProps, { Loading })(MainMenuMapped);
