import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params) => ({
  authFrameOuter: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "2.75em",
    lineHeight: "1.5",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5em",
      marginTop: "1em"
    }
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
