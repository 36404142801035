import * as types from "../actions/actionTypes";
import { initialState } from "./initialState";

export const loader = (state = initialState.loader, action) => {
  switch (action.type) {
    case types.LOADER:
      return { loader: action.data };
    default:
      return state;
  }
};
