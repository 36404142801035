export const Items = {
  menu: [
    {
      name: "Home",
      to: "/"
    },
    {
      name: "About Us",
      to: "/about-us"
    },
    {
      name: "Services",
      to: "/services"
    }
  ]
};
