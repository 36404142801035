// LOADER & constants
export const LOADER = "LOADER";
export const SEARCH_DATE = "SEARCH_DATE";
export const PAGINATION = "PAGINATION";
export const RESET_STATE = 'RESET_STATE';


// get logged in profile data
export const PROFILE_DATA = "PROFILE_DATA";
export const PROFILE_DATA_SUCCESS = "PROFILE_DATA_SUCCESS";
export const PROFILE_DATA_ERROR = "PROFILE_DATA_ERROR";

// Auth Action type
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

// Roles Constants
export const ROLES_LIST = "ROLES_LIST";
export const ROLES_LIST_SUCCESS = "ROLES_LIST_SUCCESS";
export const ROLES_LIST_ERROR = "ROLES_LIST_ERROR";

export const ROLE_BY_ID = "ROLE_BY_ID";
export const ROLE_BY_ID_SUCCESS = "ROLE_BY_ID_SUCCESS";
export const ROLE_BY_ID_ERROR = "ROLE_BY_ID_ERROR";

export const ROLES_ASSOCIATED_USERS = "ROLES_ASSOCIATED_USERS";
export const ROLES_ASSOCIATED_USERS_SUCCESS = "ROLES_ASSOCIATED_USERS_SUCCESS";
export const ROLES_ASSOCIATED_USERS_ERROR = "ROLES_ASSOCIATED_USERS_ERROR";

export const EMPTY_ASSOCIATED_USERS = "EMPTY_ASSOCIATED_USERS";

// role permissions
export const ALL_PERMISSIONS = "ALL_PERMISSIONS";
export const ALL_PERMISSIONS_SUCCESS = "ALL_PERMISSIONS_SUCCESS";
export const ALL_PERMISSIONS_ERROR = "ALL_PERMISSIONS_ERROR";

export const USER_PERMISSIONS = "USER_PERMISSIONS";
export const USER_PERMISSIONS_SUCCESS = "USER_PERMISSIONS_SUCCESS";
export const USER_PERMISSIONS_ERROR = "USER_PERMISSIONS_ERROR";

// Users Constants
export const USERS_LIST = "USERS_LIST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_ERROR = "USERS_LIST_ERROR";

export const USERDATA_BY_ID = "USERDATA_BY_ID";
export const USERDATA_BY_ID_SUCCESS = "USERDATA_BY_ID_SUCCESS";
export const USERDATA_BY_ID_ERROR = "USERDATA_BY_ID_ERROR";

// entities Constants
export const ENTITIES_LIST = "ENTITIES_LIST";
export const ENTITIES_LIST_SUCCESS = "ENTITIESR_LIST_SUCCESS";
export const ENTITIES_LIST_ERROR = "ENTITIES_LIST_ERROR";

// seller Constants
export const SELLER_LIST_INVOICE = "SELLER_LIST_INVOICE";
export const SELLER_LIST_INVOICE_SUCCESS = "SELLER_LIST_INVOICE_SUCCESS";
export const SELLER_LIST_INVOICE_ERROR = "SELLER_LIST_INVOICE_ERROR";

// seller invoice Constants
export const SELLER_LIST = "SELLER_LIST";
export const SELLER_LIST_SUCCESS = "SELLER_LIST_SUCCESS";
export const SELLER_LIST_ERROR = "SELLER_LIST_ERROR";

// seller list by EID Constants
export const SELLER_LIST_EID = "SELLER_LIST_EID";
export const SELLER_LIST_EID_SUCCESS = "SELLER_LIST_EID_SUCCESS";
export const SELLER_LIST_EID_ERROR = "SELLER_LIST_EID_ERROR";

// buyers list by EID Constants
export const BUYER_LIST_EID = "BUYER_LIST_EID";
export const BUYER_LIST_EID_SUCCESS = "BUYER_LIST_EID_SUCCESS";
export const BUYER_LIST_EID_ERROR = "BUYER_LIST_EID_ERROR";

export const SELLER_BY_ID = "SELLER_BY_ID";
export const SELLER_BY_ID_SUCCESS = "SELLER_BY_ID_SUCCESS";
export const SELLER_BY_ID_ERROR = "SELLER_BY_ID_ERROR";

// BUYER Constants
export const BUYER_LIST = "BUYER_LIST";
export const BUYER_LIST_SUCCESS = "BUYER_LIST_SUCCESS";
export const BUYER_LIST_ERROR = "BUYER_LIST_ERROR";

// BUYER invoice Constants
export const BUYER_LIST_INVOICE = "BUYER_LIST_INVOICE";
export const BUYER_LIST_INVOICE_SUCCESS = "BUYER_LIST_INVOICE_SUCCESS";
export const BUYER_LIST_INVOICE_ERROR = "BUYER_LIST_INVOICE_ERROR";

// first party gstin data 
export const FIRST_PARTY_GSTIN_LIST = "FIRST_PARTY_GSTIN_LIST";
export const FIRST_PARTY_GSTIN_LIST_SUCCESS = "FIRST_PARTY_GSTIN_LIST_SUCCESS";
export const FIRST_PARTY_GSTIN_LIST_ERROR = "FIRST_PARTY_GSTIN_LIST_ERROR";


// second party gstin data 
export const SECOND_PARTY_GSTIN_LIST = "SECOND_PARTY_GSTIN_LIST";
export const SECOND_PARTY_LIST_GSTIN_SUCCESS = "SECOND_PARTY_LIST_GSTIN_SUCCESS";
export const SECOND_PARTY_GSTIN_LIST_ERROR = "SECOND_PARTY_GSTIN_LIST_ERROR";


export const BUYER_BY_ID = "BUYER_BY_ID";
export const BUYER_BY_ID_SUCCESS = "BUYER_BY_ID_SUCCESS";
export const BUYER_BY_ID_ERROR = "BUYER_BY_ID_ERROR";


// Invoice List
export const INVOICE_LIST = "INVOICE_LIST";
export const INVOICE_LIST_SUCCESS = "INVOICE_LIST_SUCCESS";
export const INVOICE_LIST_ERROR = "INVOICE_LIST_ERROR";

// Dashboard Data List
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const DASHBOARD_DATA_SUCCESS = "DASHBOARD_DATA_SUCCESS";
export const DASHBOARD_DATA_ERROR = "DASHBOARD_DATA_ERROR";

// send  invoice
export const SEND_INVOICE_LIST = "SEND_INVOICE_LIST";
export const SEND_INVOICE_LIST_SUCCESS = "SEND_INVOICE_LIST_SUCCESS";
export const SEND_INVOICE_LIST_ERROR = "SEND_INVOICE_LIST_ERROR";


// Invoice Acceptance List
export const INVOICE_ACCEPTANCE_LIST = "INVOICE_ACCEPTANCE_LIST";
export const INVOICE_ACCEPTANCE_SUCCESS = "INVOICE_ACCEPTANCE_SUCCESS";
export const INVOICE_ACCEPTANCE_ERROR = "INVOICE_ACCEPTANCE_ERROR";

//  invoice list ocr
export const INVOICE_LIST_OCR = "INVOICE_LIST_OCR";
export const INVOICE_LIST_OCR_SUCCESS = "INVOICE_LIST_OCR_SUCCESS";
export const INVOICE_LIST_OCR_ERROR = "INVOICE_LIST_OCR_ERROR";

//  invoice list logs
export const INVOICE_LIST_LOGS = "INVOICE_LIST_LOGS";
export const INVOICE_LIST_LOGS_SUCCESS = "INVOICE_LIST_LOGS_SUCCESS";
export const INVOICE_LIST_LOGS_ERROR = "INVOICE_LIST_LOGS_ERROR";

//  auto fil up account name using pan
export const BUYER_SELLERS_LIST_AUTO_POPULATE = "BUYER_SELLERS_LIST_AUTO_POPULATE";
export const BUYER_SELLERS_LIST_AUTO_POPULATE_SUCCESS = "BUYER_SELLERS_LIST_AUTO_POPULATE_SUCCESS";
export const BUYER_SELLERS_LIST_AUTO_POPULATE_ERROR = "BUYER_SELLERS_LIST_AUTO_POPULATE_ERROR";


//  auto fil up email check
export const GET_EMAIL_CHECK = "GET_EMAIL_CHECK";
export const GET_EMAIL_CHECK_SUCCESS = "GET_EMAIL_CHECK_SUCCESS";
export const GET_EMAIL_CHECK_ERROR = "GET_EMAIL_CHECK_ERROR";
//  auto fil up address details using gstin
export const BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN = "BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN";
export const BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN_SUCCESS = "BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN_SUCCESS";
export const BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN_ERROR = "BUYER_SELLERS_LIST_AUTO_POPULATE_GSTIN_ERROR";

//  bank account details validate
export const BANK_ACCOUNT_DETAILS_VALIDATE = "BANK_ACCOUNT_DETAILS_VALIDATE";
export const BANK_ACCOUNT_DETAILS_VALIDATE_SUCCESS = "BANK_ACCOUNT_DETAILS_VALIDATE_SUCCESS";
export const BANK_ACCOUNT_DETAILS_VALIDATE_ERROR = "BANK_ACCOUNT_DETAILS_VALIDATE_ERROR";

// Invoice verification send List
export const INVOICE_VERIFICATION_SEND_LIST = "INVOICE_VERIFICATION_SEND_LIST";
export const INVOICE_VERIFICATION_SEND_LIST_SUCCESS = "INVOICE_VERIFICATION_SEND_LIST_SUCCESS";
export const INVOICE_VERIFICATION_SEND_LIST_ERROR = "INVOICE_VERIFICATION_SEND_LIST_ERROR";

// Invoice e way bill List
export const INVOICE_EWAY_BILL_LIST = "INVOICE_EWAY_BILL_LIST";
export const INVOICE_EWAY_BILL_LIST_SUCCESS = "INVOICE_EWAY_BILL_LIST_SUCCESS";
export const INVOICE_EWAY_BILL_LIST_ERROR = "INVOICE_EWAY_BILL_LIST_ERROR";

// send consent link data
export const SEND_CONSENT_LINK = "SEND_CONSENT_LINK";
export const SEND_CONSENT_LINK_SUCCESS = "SEND_CONSENT_LINK_SUCCESS";
export const SEND_CONSENT_LINK_ERROR = "SEND_CONSENT_LINK_ERROR";

// role type list
export const ROLE_TYPE_LIST = "ROLE_TYPE_LIST";
export const ROLE_TYPE_LIST_SUCCESS = "ROLE_TYPE_LIST_SUCCESS";
export const ROLE_TYPE_LIST_ERROR = "ROLE_TYPE_LIST_ERROR";

// role list by id
export const ROLE_LIST_BY_ID = "ROLE_LIST_BY_ID";
export const ROLE_LIST_BY_ID_SUCCESS = "ROLE_LIST_BY_ID_SUCCESS";
export const ROLE_LIST_BY_ID_ERROR = "ROLE_LIST_BY_ID_ERROR";

// role pages by id
export const ROLE_PAGES_LIST_BY_ID = "ROLE_PAGES_LIST_BY_ID";
export const ROLE_PAGES_LIST_BY_ID_SUCCESS = "ROLE_PAGES_LIST_BY_ID_SUCCESS";
export const ROLE_PAGES_LIST_BY_ID_ERROR = "ROLE_PAGES_LIST_BY_ID_ERROR";

// buyer list 
export const GET_BUYER_LIST = "GET_BUYER_LIST";
export const GET_BUYER_LIST_SUCCESS = "GET_BUYER_LIST_SUCCESS";
export const GET_BUYER_LIST_ERROR = "GET_BUYER_LIST_ERROR";

//  MIS report list 
export const GET_MIS_LIST = "MIS_LIST_LOGS";
export const GET_MIS_LIST_SUCCESS = "MIS_LIST_SUCCESS";
export const GET_MIS_LIST_ERROR = "MIS_LIST_ERROR";

//  POD report list 
export const GET_POD_TABLE = "POD_TABLE_LOGS";
export const GET_POD_TABLE_SUCCESS = "POD_TABLE_SUCCESS";
export const GET_POD_TABLE_ERROR = "POD_TABLE_ERROR";

// Entity list data 
export const ENTITY_LIST = "ENTITY_LIST";
export const ENTITY_LIST_SUCCESS = "ENTITY_LIST_SUCCESS";
export const ENTITY_LIST_ERROR = "ENTITY_LIST_ERROR";

// POD list data 
export const POD_LIST = "POD_LIST";
export const POD_LIST_SUCCESS = "POD_LIST_SUCCESS";
export const POD_LIST_ERROR = "POD_LIST_ERROR";

// POD data 
export const POD_DATA = "POD_DATA";
export const POD_DATA_SUCCESS = "POD_DATA_SUCCESS";
export const POD_DATA_ERROR = "POD_DATA_ERROR";

// POD update 
export const POD_UPDATE = "POD_UPDATE";
export const POD_UPDATE_SUCCESS = "POD_UPDATE_SUCCESS";
export const POD_UPDATE_ERROR = "POD_UPDATE_ERROR";