import React, { useState } from "react";
import moment from "moment";
var myCurrentDate = new Date();
var date =
  myCurrentDate.getMonth() +
  1 +
  "/" +
  myCurrentDate.getDate() +
  "/" +
  myCurrentDate.getFullYear() +
  " " +
  myCurrentDate.getHours() +
  ":" +
  myCurrentDate.getMinutes() +
  ":" +
  myCurrentDate.getSeconds();
const newCurrentDate = moment(date).format("MM/DD/YYYY hh:mm:ss:A");

export default module = {
  name: `Accept My Invoice`,
  desc: "Accept My Invoice | Web App",
  prefix: "accept-my-invoice",
  footerText: "&copy; Accept My Invoice | All Rights Reserved",
  logoText: "Accept My Invoice"
};
