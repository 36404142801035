import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import dummy from "../../assets/dummy/dummyContents";
import useStyles from "./header-jss";
import { useAuth } from "src/hooks/useAuth";
import { closeMenuAction } from "src/redux/actions/uiActions";
import { connect } from "react-redux";

function UserMenu(props) {
  const { closeDrawer } = props;
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    openMenu: null,
  });
  const { user, logout } = useAuth();

  const handleMenu = (menu) => (event) => {
    const { openMenu } = menuState;
    setMenuState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null, openMenu: null });
  };

  const logoutApp = () => {
    closeDrawer();
    logout();
  };

  const { anchorEl, openMenu } = menuState;
  const imageURL = `${process.env.REACT_APP_AWS_PATH}${process.env.REACT_APP_AWS_PROFILE_PICTURES}${props?.profile?.data?.ProfilePic}`;

  return (
    <>
      <Button onClick={handleMenu("user-setting")}>
        {props.profile && props.profile.data && imageURL ? (
          <Avatar
            alt={props.profile.data?.FirstName}
            src={imageURL}
          />
        ) : (
          <Avatar alt="Dummy" src={dummy.user.avatar} />
        )}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={openMenu === "user-setting"}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate("/app/user/profile")}>
          Profile
        </MenuItem>
        {/* <MenuItem onClick={() => navigate("/app")}>Dashboard</MenuItem> */}
        <Divider />
        {/* {!!user && ( */}
          <MenuItem key="logout" onClick={() => logoutApp()}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            Log Out
          </MenuItem>
        {/* )} */}
      </Menu>
    </>
  );
}

UserMenu.propTypes = {};

const mapStateToProps = (state) => ({
  closeDrawer: PropTypes.func,
  profile: state.rootReducer.permissions.profileData,
});

const mapDispatchToProps = (dispatch) => ({
  closeDrawer: () => dispatch(closeMenuAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
